import React, { useEffect, useState } from 'react'
import searchImg from "../../assets/img/svg/search.svg"
import tm6 from "../../assets/img/tm6.png"
import { Link } from 'react-router-dom'
import axios from 'axios'
import baseUrl from '../Helper/BaseUrl'
import TruncatedText from '../Helper/TruncatedText'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
const Contacts = () => {
    const [emailList, setEmailList] = useState([])
    const fetchSubscribers = async () => {
        try {
            const res = await axios.get(`${baseUrl}/help-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log(res)
            setEmailList(res.data.data)
        } catch (error) {
            console.log("error fetch emails", error)
        }
    }
    useEffect(() => {
        fetchSubscribers();
    }, [])

    const handleCategoryDelete = async (catId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    const res = await axios.delete(`${baseUrl}/help-delete/${catId}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    })
                    if (res.data.status) {
                        toast.success(res.data.message)
                        setEmailList(prevCat => prevCat.filter(cat => cat._id !== catId));
                    } else {
                        toast.error(res.data.message)
                    }

                } catch (error) {
                    console.log('error delete subcategory', error)
                }
            }
        })

    }
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main">
                            <h4 className="text-capitalize breadcrumb-title">help and support</h4>
                            <div className="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"><i className="uil uil-estate"></i>Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">help and support</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            {/* <div className="card-header">
                                <form
                                    action=""
                                    className="user-member__form"
                                >

                                    <div className='with-icon'>
                                        <span className="uil uil-search"></span>
                                        <input
                                            className="form-control"
                                            type="search"
                                            placeholder="Search by Name"
                                            aria-label="Search"
                                        // onChange={(e) => setSearchText(e.target.value)}
                                        />
                                    </div>
                                </form>
                            </div> */}
                            <div className="card-body">
                                <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                                    <div className="table-responsive">
                                        <table className="table mb-0 table-borderless">
                                            <thead>
                                                <tr className="userDatatable-header">
                                                    <th><span className="userDatatable-title">S.No.</span></th>
                                                    <th>
                                                        <span className="userDatatable-title">full name</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">phone</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">message</span>
                                                    </th>                                               
                                                    <th>
                                                        <span className="userDatatable-title">created At</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">
                                                            action
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    emailList.map((emails, index) => {
                                                        return (
                                                            <tr key={emails._id}>
                                                                <td>
                                                                    <div className="userDatatable-content">{index + 1}</div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-inline-title">
                                                                        <h6>{emails.full_name}</h6>
                                                                        <div style={{ fontSize: "12px", lineHeight: 0 }}>
                                                                            ( {emails.email})
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content">
                                                                        {emails.phone}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content wrap-code" >
                                                                        <TruncatedText
                                                                            text={emails.message}
                                                                            limit={20}
                                                                        />
                                                                    </div>
                                                                </td>                                                             
                                                                <td>
                                                                    <div className="userDatatable-content">
                                                                        {new Date(emails.createdAt).toLocaleString()}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <ul className="orderDatatable_actions mb-0">
                                                                        <li>
                                                                            <a type='button' onClick={() => handleCategoryDelete(emails?._id)} className="remove">
                                                                                <i className="uil uil-trash-alt" />
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Contacts