import React, { useEffect, useState } from 'react'
import upload from "../../assets/img/svg/upload.svg"
import percent from "../../assets/img/svg/percent.svg"
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import axios from 'axios'
import ReactQuill from 'react-quill'
const AddProduct = () => {
  const [values, setValues] = useState({
    category_id: "",
    brand_id: "",
    product_name: "",
    product_Number: "",
    price: "",
    is_discount: false,
    discount_price: "",
    in_stock: false,
    stock_qty: "",
    authentic: "",
    is_variation_add: false,
    variation: "",
    sort_description: "",
    status: "",
    sequence: "",
    reviewRating: "",
    location: "",
    weight_price: "",
    weight_price: [{ weight: "150g", prices: { India: "", Other: "", } }, { weight: "200g", prices: { India: "", Other: "", } }],
  })
  const [catList, setCatList] = useState([]);
  const [states, setStates] = useState([]);
  // product_images
  const [imgFile, setImgFiles] = useState([])
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'discount_price') {
      // Only allow values between 0 and 100
      const numValue = parseInt(value);
      if (numValue > 100) return;
      if (numValue < 0) return;
    }
    setValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  }
  const [description, setDiscription] = useState('');
  const modules = {
    toolbar: {
      container: [
        [{ header: [2, 3, 4, false] }],
        ["bold", "italic", "underline", "blockquote"],
        [{ color: [] }],
        [{ background: [] }],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image"],
        ["clean"],
      ],
      // handlers: {
      //     image: imageHandler,
      // },
    },
    clipboard: {
      matchVisual: true,
    },
  }
  // category api
  const fetchCategory = async () => {
    try {
      const res = await axios.get(`${baseUrl}/category-list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      // console.log("rescat", res.data.data)
      setCatList(res.data.data)
    } catch (error) {
      console.log("fetch cat error", error)
    }
  }

  // state list api
  const fetchStates = async () => {
    try {
      const res = await axios.get(`${imgUrl}user/state-list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      // console.log("state", res.data.data)
      setStates(res.data.data)
    } catch (error) {
      console.log("fetch states error", error)
    }
  }

  useEffect(() => {
    fetchCategory();
    fetchStates();
  }, []);

  const handleWeightPriceChange = (weightIndex, country, value) => {
    setValues(prevValues => {
      const newWeightPrice = [...prevValues.weight_price];
      newWeightPrice[weightIndex].prices[country] = parseInt(value);
      return {
        ...prevValues,
        weight_price: newWeightPrice
      };
    });
  };
  // image file remove
  const handleImageRemove = (index) => {
    setImgFiles(prevFiles => prevFiles.filter((file, i) => i !== index));
  };
  // handle file change
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImgFiles(prevFiles => [...prevFiles, ...files]);
  };
  // handle post api
  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastId = "product-Id";
    if (!values.category_id || !values.product_name  || !values.product_Number||
      !values.authentic || !values.status || !values.sequence || !values.location
    ) {
      if (!toast.isActive(toastId)) {
        toast.error("All fields are required!", { toastId: toastId });
      }
      return;
    }
    if (!values.is_discount) {
      if (!toast.isActive(toastId)) {
        toast.error('Discount status is required', { toastId: toastId });
      }
    } else if (values.is_discount === 'true' && !values.discount_price) {
      if (!toast.isActive(toastId)) {
        toast.error('Discount price is required', { toastId: toastId })
      }
    }
    if (!values.in_stock) {
      if (!toast.isActive(toastId)) {
        toast.error('stock status is required', { toastId: toastId })
      }
    } else if (values.in_stock === 'true' && !values.stock_qty) {
      if (!toast.isActive(toastId)) {
        toast.error('stock quantity is required', { toastId: toastId })
      }
    }
    if (imgFile.length === 0) {
      if (!toast.isActive(toastId)) {
        toast.error("Please upload at least one image.", { toastId: toastId });
      }
      return;
    }
    if (!imgFile) {
      if (!toast.isActive(toastId)) {
        toast.error("Please upload an image.", { toastId: toastId });
      }
      return;
    }
    try {
      const formData = new FormData();
      formData.append('category_id', values.category_id)
      formData.append('product_name', values.product_name)
      formData.append('product_Number', values.product_Number)
      formData.append('price', 0)
      formData.append('is_discount', values.is_discount)
      if (values.is_discount === 'true') {
        formData.append('discount_price', values.discount_price)
      }
      formData.append('in_stock', values.in_stock)
      if (values.in_stock === 'true') {
        formData.append('stock_qty', values.stock_qty)
      }
      formData.append('authentic', values.authentic)
      formData.append('description', description)
      formData.append('sort_description', values.sort_description)
      formData.append('status', values.status)
      formData.append('sequence', values.sequence)
      formData.append('location', values.location)
      formData.append('weight_price', JSON.stringify(values.weight_price));
      imgFile.forEach(file => {
        formData.append('product_images', file); // Append each image
      });
      const res = await axios.post(`${baseUrl}/product-store`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      // console.log("****",res);
      if (res.data.status) {
        toast.success(res.data.message)
        navigate('/productlist')
      } else {
        toast.error(res.data.message)
      }

    } catch (error) {
      console.log("product post error", error)
    }
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="shop-breadcrumb">
              <div className="breadcrumb-main">
                <h4 className="text-capitalize breadcrumb-title">
                  add product
                </h4>
                <div className="breadcrumb-action justify-content-center flex-wrap">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <i className="uil uil-estate" />
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        add product
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className='card'>
              <div className='card-header'></div>
              <div className='card-body'>
                <form onSubmit={handleSubmit}>
                  <div className='form-basic'>
                    <div className='row'>
                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <label htmlFor="name1">Product Name</label>
                          <input type="text" className="form-control" id="name1" name='product_name' value={values.product_name} onChange={handleChange} placeholder="add product name" />
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <label htmlFor="name2">Product Number</label>
                          <input type="text" className="form-control" id="name2" name='product_Number' value={values.product_Number} onChange={handleChange} placeholder="add product Number" />
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <label htmlFor="name2">Sort Description</label>
                          <input type="text" className="form-control" name='sort_description' value={values.sort_description} onChange={handleChange} id="name2" placeholder="sort description"
                          />
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <div className="countryOption">
                            <label htmlFor="countryOption">category</label>
                            <select name='category_id' value={values.category_id} onChange={handleChange} className="form-select" id="countryOption" >
                              <option value="">Select Category</option>
                              {
                                catList.map((cat, index) => {
                                  return (
                                    <option key={index} value={cat._id}>{cat.name}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <div className="countryOption">
                            <label htmlFor="countryOption">Authentic</label>
                            <select name='authentic' value={values.authentic} onChange={handleChange} className="form-select" id="countryOption"
                            >
                              <option value="">Select Authentication</option>
                              <option value="true">Active</option>
                              <option value="false">Inactive</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <div className="countryOption">
                            <label htmlFor="countryOption">In Stock</label>
                            <select
                              name='in_stock'
                              value={values.in_stock}
                              onChange={handleChange}
                              className="form-select"
                              id="countryOption"
                            >
                              <option value="">Select Stock</option>
                              <option value={true}>In Stock</option>
                              <option value={false}>Out of Stock</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      {
                        values.in_stock === 'true' && (
                          <div className='col-lg-4 col-md-4 mb-3'>
                            <div className="form-group">
                              <div className="countryOption">
                                <label htmlFor="countryOption">Stock Quantity</label>
                                <input
                                  type="number"
                                  name='stock_qty'
                                  value={values.stock_qty}
                                  onChange={handleChange}
                                  className="form-control"
                                  id="name8"
                                  placeholder="enter stock quantity"
                                />
                              </div>
                            </div>
                          </div>
                        )
                      }
                      {values.weight_price.map((weightItem, index) => (
                        <div key={index} className="row">
                          <div className='col-lg-4 col-md-4 mb-3'>
                            <div className="form-group">
                              <label>{weightItem.weight}</label>
                              <input
                                type="text"
                                className="form-control"
                                value={weightItem.weight}
                                disabled
                              />
                            </div>
                          </div>

                          <div className='col-lg-4 col-md-4 mb-3'>
                            <div className="form-group quantity-appearance">
                              <label>Indian price ({weightItem.weight})</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fa-solid fa-indian-rupee-sign fs-3"></i>
                                  </span>
                                </div>
                                <div className="pt_Quantity">
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={weightItem.prices.India}
                                    onChange={(e) => handleWeightPriceChange(index, 'India', e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='col-lg-4 col-md-4 mb-3'>
                            <div className="form-group quantity-appearance">
                              <label>International price ({weightItem.weight})</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fa-solid fa-indian-rupee-sign fs-3"></i>
                                  </span>
                                </div>
                                <div className="pt_Quantity">
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={weightItem.prices.Other}
                                    onChange={(e) => handleWeightPriceChange(index, 'Other', e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <div className="countryOption">
                            <label htmlFor="countryOption">Is Discount</label>
                            <select name='is_discount' value={values.is_discount} onChange={handleChange} className="form-select" id="countryOption" >
                              <option value="">select Discount</option>
                              <option value={true}>Active</option>
                              <option value={false}>Inactive</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {
                        values.is_discount === 'true' && (
                          <div className='col-lg-4 col-md-4 mb-3'>
                            <div className="form-group quantity-appearance">
                              <label>Discount Price</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text" id="basic-addon2"><img className="svg" src={percent} alt="" />
                                  </span>
                                </div>
                                <div className="pt_Quantity">
                                  <input type="number" className="form-control" name='discount_price'
                                    value={values.discount_price}
                                    onChange={handleChange}
                                    min={0}
                                    max={100}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }

                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <label htmlFor="name8"> sequence </label>
                          <input type="number" name='sequence' value={values.sequence} onChange={handleChange} className="form-control" id="name8" placeholder="enter sequence" />
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <div className="countryOption">
                            <label htmlFor="countryOption">State</label>
                            <select
                              name='location'
                              value={values.location}
                              onChange={handleChange}
                              className="form-select"
                              id="countryOption"
                            >
                              <option value="">select location</option>
                              {
                                states.map((state, index) => {
                                  return (
                                    <option key={state._id} value={state.name}>{state.name}</option>
                                  )
                                })
                              }

                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <div className="countryOption">
                            <label htmlFor="countryOption">Status</label>
                            <select
                              name='status'
                              value={values.status}
                              onChange={handleChange}
                              className="form-select"
                              id="countryOption"
                            >
                              <option value="">select status</option>
                              <option value="true">Active</option>
                              <option value="false">Inactive</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-12 col-md-12 mb-3'>
                        <div className="form-group">
                          <label htmlFor="name2">Description</label>
                          <ReactQuill theme="snow" modules={modules} value={description} onChange={setDiscription} />
                          {/* <input type="text" className="form-control" name='description' value={values.description} onChange={handleChange} id="name2" placeholder="description" /> */}
                        </div>
                      </div>
                      <div className='col-lg-12 col-md-4 mb-3'>
                        <h6 className="fw-500">Product image (size in pixels(524*393))</h6>
                        <label
                          htmlFor="upload"
                          className="file-upload__label"
                        >
                          <span className="upload-product-img px-10 d-block">
                            <span className="file-upload">
                              <img className="svg" src={upload} alt="" />
                              <input id="upload" className="file-upload__input" type="file" name="file-upload" multiple onChange={handleImageChange}
                              />
                            </span>
                          </span>
                        </label>
                        <div className='upload-part'>

                          {imgFile.length > 0 && (
                            imgFile.map((file, index) => (
                              <div className='upload-part-innr'>
                                <img src={URL.createObjectURL(file)} alt={`preview-${index}`} />
                                <a type="button" onClick={() => handleImageRemove(index)} > <i className='uil uil-times'></i> </a>
                              </div>
                            )))}

                        </div>
                      </div>
                      <div className='d-flex justify-content-end'>
                        <button type='submit' className="btn btn-primary btn-default btn-squared text-capitalize">
                          save product
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddProduct