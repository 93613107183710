import React, { useEffect, useState } from 'react'
import searchImg from "../../assets/img/svg/search.svg"
import tm6 from "../../assets/img/tm6.png"
import { Link } from 'react-router-dom'
import axios from 'axios'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
const CouponList = () => {
    const [couponList, setCouponList] = useState([]);
    const [loading, setloading] = useState(false)
    useEffect(() => {
        fetchCoupons();
    }, [])

    // fetch category api
    const fetchCoupons = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/coupon-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("rescat", res.data.data)
            setCouponList(res.data.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("fetch coupon error", error)
        }
    }

    // delete api
    const handleCouponDelete = async (catId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    const res = await axios.delete(`${baseUrl}/coupon-delete/${catId}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    })
                    if (res.data.status) {
                        toast.success(res.data.message)
                        setCouponList(prevCat => prevCat.filter(cat => cat._id !== catId));
                    } else {
                        toast.error(res.data.message)
                    }

                } catch (error) {
                    console.log('error delete coupon', error)
                }
            }
        })

    }
    const handleToggleStatus = async (catId, currentStatus) => {
        try {
            setloading(true)
            const newStatus = !currentStatus;
            const res = await axios.get(`${baseUrl}/coupon-status-change/${catId}`,
                { status: newStatus },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
            console.log(res);
            if (res.data.status) {
                toast.success(res.data.message)
                setloading(false)
                setCouponList(prevCat =>
                    prevCat.map(cat =>
                        cat._id === catId ? { ...cat, status: newStatus } : cat
                    )
                )
            }
        } catch (error) {
            setloading(false)
            console.log("toggle category status error", error)
        }
    }
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main">
                            <h4 className="text-capitalize breadcrumb-title">Coupons list</h4>
                            <div className="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"><i className="uil uil-estate"></i>Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Coupons list</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header d-flex justify-content-end">
                                {/* <form
                                    action=""
                                    className="user-member__form"
                                >

                                    <div className='with-icon'>
                                        <span className="uil uil-search"></span>
                                        <input
                                            className="form-control"
                                            type="search"
                                            placeholder="Search by Name"
                                            aria-label="Search"
                                        // onChange={(e) => setSearchText(e.target.value)}
                                        />
                                    </div>
                                </form> */}
                                    <Link className="btn btn-primary" to="/addcoupon">
                                        <span className="uil uil-plus"></span>Add Coupon</Link>
                            </div>
                            <div className="card-body">
                                <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                                    <div className="table-responsive">
                                        <table className="table mb-0 table-borderless">
                                            <thead>
                                                <tr className="userDatatable-header">
                                                    <th><span className="userDatatable-title">S.No.</span></th>
                                                    <th>
                                                        <span className="userDatatable-title">Coupon image</span>
                                                    </th>
                                                    <th>
                                                        <span className="checkbox-text userDatatable-title">
                                                            Coupon Name
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Coupon code</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Start Date</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">End Date</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Discount</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">status</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title float-end">
                                                            action
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    couponList.map((cat, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <div className="userDatatable-content">{index + 1}</div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content">
                                                                        <img src={`${imgUrl}${cat.image}`} className="profile-image rounded-circle d-block m-0 wh-38" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-inline-title">

                                                                        <h6>{cat.coupon_name}</h6>

                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content">
                                                                        {cat.code}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content">
                                                                        {cat.start_date}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content">
                                                                        {cat.end_date}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content">
                                                                        {cat.discount}
                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    <div className="userDatatable-content d-inline-block">
                                                                        <div className="form-check form-switch">
                                                                            <input className="form-check-input" checked={cat.status} onChange={() => handleToggleStatus(cat._id, cat.status)} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                                        <li>
                                                                            <Link to={`/couponview/${cat._id}`} className="view">
                                                                                <i className="uil uil-eye" />
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to={`/editcoupon/${cat._id}`} className="edit">
                                                                                <i className="uil uil-edit" />
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <a type='button' onClick={() => handleCouponDelete(cat?._id)} className="remove">
                                                                                <i className="uil uil-trash-alt" />
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CouponList