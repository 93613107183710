import React, { useEffect, useState } from 'react'
import searchImg from "../../assets/img/svg/search.svg";
import { Link } from 'react-router-dom';
import baseUrl from '../Helper/BaseUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
const TeamMember = () => {
    const [staffList, setStaffList] = useState([])
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('')
    useEffect(() => {
        fetchStaff();
    }, [searchText])
    const fetchStaff = async () => {
        // setLoading(true)
        try {
            const res = await axios.get(`${baseUrl}/staff-list?name=${searchText}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("staff", res.data)
            setStaffList(res.data.data)
            // setLoading(false)
        } catch (error) {
            // setLoading(false)
            console.log("fetch staff error", error)
        }
    }

    //  handle status change
    const handleStatusToggle = async (listId, currentStatus) => {
        try {
            setLoading(true)
            const newStatus = !currentStatus;
            const response = await axios.get(
                `${baseUrl}/staff-status-change/${listId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );
            // console.log(response)
            if (response.data.status) {
                toast.success(response.data.message)
                setLoading(false)
                setStaffList(prevState =>
                    prevState.map(list =>
                        list._id === listId ? { ...list, status: newStatus } : list
                    )
                );
            }
        } catch (error) {
            setLoading(false)
            console.error('Error changing user status:', error);
        }
    };
    // delete api
    const handleStaffDelete = async (roleId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await axios.delete(`${baseUrl}/staff-delete/${roleId}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    })
                    if (res.data.status) {
                        toast.success(res.data.message)
                        setStaffList(prevUnit => prevUnit.filter(role => role._id !== roleId));
                    } else {
                        toast.error(res.data.message)
                    }

                } catch (error) {
                    console.log('error delete staff', error)
                }
            }
        })

    }
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>)}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div class="breadcrumb-main">
                            <h4 class="text-capitalize breadcrumb-title">Staff list</h4>
                            <div class="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><Link to="/"><i className="uil uil-estate"></i>Dashboard</Link></li>
                                        <li class="breadcrumb-item active" aria-current="page">Staff list</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <form
                                    action=""
                                    className="user-member__form"
                                >
                                    <div className='with-icon'>
                                        <span class="uil uil-search"></span>
                                        <input
                                            className="form-control"
                                            type="search"
                                            placeholder="Search by Name"
                                            aria-label="Search"
                                            value={searchText}
                                            onChange={(e) => setSearchText(e.target.value)}
                                        />
                                    </div>
                                </form>
                                <div>
                                    <Link className="btn btn-primary" to="/addstaff"><span class="uil uil-plus"></span> Add staff</Link>
                                </div>
                            </div>
                            {
                                staffList?.length > 0 ? (
                                    <div class="card-body">
                                        <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                                            <div className="table-responsive">
                                                <table className="table mb-0 table-borderless">
                                                    <thead>
                                                        <tr className="userDatatable-header">
                                                            <th>
                                                                <span className="userDatatable-title">
                                                                    S. No.
                                                                </span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">
                                                                    staff name
                                                                </span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">staff emaill</span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">staff role</span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">Status</span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title float-end">
                                                                    action
                                                                </span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            staffList.map((list, index) => {
                                                                // console.log(list._id)
                                                                return (
                                                                    <tr key={list._id}>
                                                                        <td>
                                                                            <div className="userDatatable-content">
                                                                                {/* {++index + 10 * (page - 1)} */}
                                                                                {index + 1}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex">
                                                                                <div className="userDatatable-inline-title">
                                                                                    <h6>{list.full_name}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content">
                                                                                {list.email}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content">{list?.role?.role_name}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content d-inline-block">
                                                                                <div className="form-check form-switch">
                                                                                    <input className="form-check-input" checked={list?.status} onChange={() => handleStatusToggle(list._id, list.status)} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                                                <li>
                                                                                    <Link to={`/staffdetails/${list._id}`} className="view">
                                                                                        <i className="uil uil-eye" />
                                                                                    </Link>
                                                                                </li>
                                                                                <li>
                                                                                    <Link to={`/staffedit/${list._id}`} className="edit">
                                                                                        <i className="uil uil-edit" />
                                                                                    </Link>
                                                                                </li>
                                                                                {/* <li>
                                                                                    <a type="button" onClick={() => handleStaffDelete(list._id)} className="remove">
                                                                                        <i className="uil uil-trash-alt" />
                                                                                    </a>
                                                                                </li> */}
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <h5 className='text-center p-3'>No Data Found</h5>
                                )
                            }
                           
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TeamMember