import React, { useEffect, useState } from 'react'
import searchImg from "../../assets/img/svg/search.svg"
import tm6 from "../../assets/img/tm6.png"
import { Link } from 'react-router-dom'
import axios from 'axios'
import baseUrl from '../Helper/BaseUrl'
const NotifyEmails = () => {
    const [emailList, setEmailList] = useState([])
    const fetchSubscribers = async () => {
        try {
            const res = await axios.get(`${baseUrl}/notify-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            console.log(res)
            setEmailList(res.data.data)
        } catch (error) {
            console.log("error fetch emails", error)
        }
    }
    useEffect(() => {
        fetchSubscribers();
    }, [])
    
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main">
                            <h4 className="text-capitalize breadcrumb-title">Product Notify Emails</h4>
                            <div className="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"><i className="uil uil-estate"></i>Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Product Notify Emails</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                                    <div className="table-responsive">
                                        <table className="table mb-0 table-borderless table-striped">
                                            <thead>
                                                <tr className="userDatatable-header">
                                                    <th><span className="userDatatable-title">S.No.</span></th>
                                                    <th>
                                                        <span className="userDatatable-title">Email</span>
                                                    </th>
                                                    <th>
                                                        <span className="checkbox-text userDatatable-title">
                                                            Created at
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title float-end">
                                                            action
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    emailList.map((emails, index) => {
                                                        return (
                                                            <tr key={emails._id}>
                                                                <td>
                                                                    <div className="userDatatable-content">{index + 1}</div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content">
                                                                        {emails.email}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-inline-title">

                                                                        <h6>{new Date(emails.created_at).toLocaleString()}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                                        <li>
                                                                            <Link to={`/notifyview/${emails._id}`} className="view">
                                                                                <i className="uil uil-eye" />
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotifyEmails