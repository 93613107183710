import React, { useEffect, useState } from 'react'
import upload from "../../assets/img/svg/upload.svg"
import percent from "../../assets/img/svg/percent.svg"
import { Link, useNavigate, useParams } from 'react-router-dom'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import axios from 'axios'
import { toast } from 'react-toastify'
const EditCoupon = () => {
    const { id } = useParams();
    const [values, setValues] = useState({
        coupon_name: "",
        code: "",
        start_date:"",
        end_date:"",
        discount: "",
        description: "",
        status: "",
    })
    // coupon image
    const [preview, setPreview] = useState(null);
    const [imgFile, setImgFiles] = useState(null)
    const [loading, setloading] = useState(false)
    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }
    // fetch fetchSingleCoupons api
    const fetchSingleCoupons = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/coupon-view/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("rescat", res.data.data)
            setValues(res.data.data)
            setImgFiles(res?.data?.data?.image)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("fetch coupon error", error)
        }
    }

    // handle post api
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('coupon_name', values.coupon_name)
            formData.append('code', values.code);
            formData.append('start_date', values.start_date)
            formData.append('end_date', values.end_date)
            formData.append('discount', values.discount)
            formData.append('description', values.description)
            formData.append('status', values.status)
            if (imgFile) {
                formData.append('image', imgFile)
            }

            const res = await axios.post(`${baseUrl}/coupon-update/${id}`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("****",res);
            if (res.data.status) {
                toast.success(res.data.message)
                navigate('/couponlist')
            } else {
                toast.error(res.data.message)
            }

        } catch (error) {
            console.log("product post error", error)
        }
    }

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };
    const handleImageRemove = () => {
        setImgFiles(null);
    };
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImgFiles(file);
            setPreview(URL.createObjectURL(file));
        }
    };
    useEffect(() => {
        fetchSingleCoupons();
    }, [])
    useEffect(() => {
        return () => {
            // Cleanup preview URL when component unmounts
            if (preview) {
                URL.revokeObjectURL(preview);
            }
        };
    }, [preview]);
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    <li className="breadcrumb-item ">
                                        <Link to="/couponlist">
                                            <i className="fa-solid fa-circle-arrow-left"></i>
                                            <span>Back</span>
                                        </Link>
                                    </li>
                                    / Coupon Edit
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Coupon Edit
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className='card'>
                            <div className='card-header'></div>
                            <div className='card-body'>
                                <form onSubmit={handleSubmit}>
                                    <div className='form-basic'>
                                        <div className='row'>
                                            <div className='col-lg-4 col-md-4 mb-3'>
                                                <div className="form-group">
                                                    <label htmlFor="name1">Coupon Name</label>
                                                    <input type="text" className="form-control" id="name1" name='coupon_name'
                                                        value={values.coupon_name} onChange={handleChange} placeholder="add coupon name" />
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 mb-3'>
                                                <div className="form-group">
                                                    <label htmlFor="name2">Code</label>
                                                    <input type="text" className="form-control" name='code'
                                                        value={values.code} onChange={handleChange} id="name2" placeholder="add code" />
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 mb-3'>
                                                <div className="form-group">
                                                    <label htmlFor="name2">start Date</label>
                                                    <input type="date" className="form-control" name='start_date'
                                                        value={formatDate(values.start_date)}  onChange={handleChange} id="name2" placeholder="start date"
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 mb-3'>
                                                <div className="form-group">
                                                    <label htmlFor="name2">End Date</label>
                                                    <input type="date" className="form-control" name='end_date'
                                                        value={formatDate(values.end_date)} onChange={handleChange} id="name2" placeholder="end date"
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 mb-3'>
                                                <div className="form-group">
                                                    <label htmlFor="name2">Description</label>
                                                    <input type="text" className="form-control" name='description'
                                                        value={values.description} onChange={handleChange} id="name2" placeholder="description" />
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 mb-3'>
                                                <div className="form-group quantity-appearance">
                                                    <label>Discount</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="basic-addon1" > <img className="svg" src={percent} alt="" />
                                                            </span>
                                                        </div>
                                                        <div className="pt_Quantity">
                                                            <input type="number" className="form-control" name='discount' value={values.discount} onChange={handleChange} // min={0} // max={100} // step={1} // defaultValue={0} // data-inc={1}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 mb-3'>
                                                <div className="form-group">
                                                    <div className="countryOption">
                                                        <label htmlFor="countryOption">Status</label>
                                                        <select
                                                            name='status'
                                                            value={values.status}
                                                            onChange={handleChange}
                                                            className="form-select"
                                                            id="countryOption"
                                                        >
                                                            <option value="">select status</option>
                                                            <option value="true">Active</option>
                                                            <option value="false">Inactive</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-12 col-md-4 mb-3'>
                                                <h6 className="fw-500">Coupon image (size in pixels (30 * 30)) </h6>
                                                <label
                                                    htmlFor="upload"
                                                    className="file-upload__label"
                                                >
                                                    <span className="upload-product-img px-10 d-block">
                                                        <span className="file-upload">
                                                            <img className="svg" src={upload} alt="" />
                                                            <input id="upload" className="file-upload__input" type="file" name="file-upload" onChange={handleImageChange}
                                                            />
                                                        </span>
                                                    </span>
                                                </label>
                                                <div className='upload-part'>


                                                    {preview ? (
                                                        <div className='upload-part-innr'>
                                                            <img src={preview} alt='preview' />
                                                        </div>
                                                    ) : imgFile ? (
                                                        <div className='upload-part-innr'>
                                                            <img src={`${imgUrl}/${imgFile}`} alt='preview' />
                                                        </div>
                                                    ) : null}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                        <button type='submit' className="btn btn-primary btn-default btn-squared text-capitalize">
                                            save Coupon
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default EditCoupon
