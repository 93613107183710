import './App.css';
import './assets/style.css'
import Home from './Components/Home';
import AddProduct from './Components/Products/AddProduct';
import Login from './Components/Login/Login';
import Order from './Components/Orders/Order';
import UserList from './Components/Users/UserList';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import EditProduct from './Components/Products/EditProduct';
import Layout from './Components/Layout';
import ProductList from './Components/Products/ProductList';
import CategoryList from './Components/Category/CategoryList';
import AddCategory from './Components/Category/AddCategory';
import Brands from './Components/Brands/Brands';
import AddBrands from './Components/Brands/AddBrands';
import TeamMember from './Components/Users/TeamMember';
import OrderReport from './Components/Reports/OrderReport';
import ProductSalesReport from './Components/Reports/ProductSalesReport';
import CategoryWiseSale from './Components/Reports/CategoryWiseSale';
import SalesAmountReport from './Components/Reports/SalesAmountReport';
import DeliveryStatusReport from './Components/Reports/DeliveryStatusReport';
import Queries from './Components/Queries';
import Subscribers from './Components/Newsletter/Subscribers';
import RefundConfigurations from './Components/Refunds/RefundConfigurations';
import RefundRequests from './Components/Refunds/RefundRequests';
import ApprovedRefunds from './Components/Refunds/ApprovedRefunds';
import RejectedRefunds from './Components/Refunds/RejectedRefunds';
import Deliverymens from './Components/DeliveryMens/Deliverymens';
import AddDeliveryMen from './Components/DeliveryMens/AddDeliveryMen';
import CancelRequest from './Components/DeliveryMens/CancelRequest';
import Configurations from './Components/DeliveryMens/Configurations';
import DeliverMenPayroll from './Components/DeliveryMens/DeliverMenPayroll';
import DeliveryMenPayrollList from './Components/DeliveryMens/DeliveryMenPayrollList';
import PayoutHistory from './Components/DeliveryMens/PayoutHistory';
import UnitList from './Components/Units/UnitList';
import AddUnits from './Components/Units/AddUnits';
import SetRewardPoints from './Components/RewardWallet/SetRewardPoints';
import RewardConfigurations from './Components/RewardWallet/RewardConfigurations';
import WalletConfigurations from './Components/RewardWallet/WalletConfigurations';
import AddStock from './Components/Stocks/AddStock';
import AllLocations from './Components/Stocks/AllLocations';
import AddLocation from './Components/Stocks/AddLocation';
import Hero from './Components/Herosection/Hero';
import Sale from './Components/Herosection/Sale';
import Advertisement from './Components/Herosection/Advertisement';
import Profile from './Components/Login/Profile';
import AddStaff from './Components/Users/AddStaff';
import ShippingZone from './Components/Shipping/ShippingZone';
import AddZone from './Components/Shipping/AddZone';
import ProductReviews from './Components/Products/ProductReviews';
import Variants from './Components/Variation/Variants';
import AddVariants from './Components/Variation/AddVariants';
import EditVariants from './Components/Variation/EditVariants';
import EditBrands from './Components/Brands/EditBrands';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductView from './Components/Products/ProductView';
import CategoryView from './Components/Category/CategoryView';
import BrandView from './Components/Brands/BrandView';
import SubCategoryList from './Components/SubCategory/SubCategoryList';
import AddSubCategory from './Components/SubCategory/AddSubCategory';
import SubCategoryView from './Components/SubCategory/SubCategoryView';
import VariantView from './Components/Variation/VariantView';
import EditCategory from './Components/Category/EditCategory';
import EditSubCategory from './Components/SubCategory/EditSubCategory';
import UnitsView from './Components/Units/UnitsView';
import EditUnit from './Components/Units/EditUnit';
import VariantValuesList from './Components/Variation/VariantValuesList';
import AddVariantsValues from './Components/Variation/AddVariantsValues';
import VariantValueView from './Components/Variation/VariantValueView';
import EditVariantValue from './Components/Variation/EditVariantValue';
import UserListView from './Components/Users/UserListView';
import EditUser from './Components/Users/EditUser';
import ResetPassword from './Components/Users/ResetPassword';
import ExpireLink from './Components/Users/ExpireLink';
import ForgotPassword from './Components/Login/ForgotPassword'
import VerifyOtp from './Components/Login/VerifyOtp'
import RoleList from './Components/Roles/RoleList';
import AddRole from './Components/Roles/AddRole';
import RoleView from './Components/Roles/RoleView';
import EditRole from './Components/Roles/EditRole';
import StaffView from './Components/Users/StaffView';
import EditStaff from './Components/Users/EditStaff';
import ProtectedRoute from './Components/Routes/ProtectedRoute';
import PageNotFound from './Components/PageNotFound';
import ChangePassword from './Components/Login/ChangePassword';
import OrderView from './Components/Orders/OrderView';
import CouponList from './Components/Coupon/CouponList';
import AddCoupon from './Components/Coupon/AddCoupon';
import CouponView from './Components/Coupon/CouponView';
import EditCoupon from './Components/Coupon/EditCoupon';
import BannerSlider from './Components/Herosection/BannerSlider';
import SaleBanner from './Components/Herosection/SaleBanner';
import BannerSliderView from './Components/Herosection/BannerSliderView';
import EditBannerSlider from './Components/Herosection/EditBannerSlider';
import CmsList from './Components/cms/CmsList';
import AddCms from './Components/cms/AddCms';
import CmsView from './Components/cms/CmsView';
import EditCms from './Components/cms/EditCms';
import FaqList from './Components/FAQ/FaqList';
import AddFaq from './Components/FAQ/AddFaq';
import FaqView from './Components/FAQ/FaqView';
import EditFaq from './Components/FAQ/EditFaq';
import Settings from './Components/Newsletter/Settings';
import Contacts from './Components/Newsletter/Contacts';
import StateManage from './Components/StateManage/StateManage';
import StateView from './Components/StateManage/StateView';
import StateEdit from './Components/StateManage/StateEdit';
import CorporateGift from './Components/CorporateGift/CorporateGift';
import CorporateGiftView from './Components/CorporateGift/CorporateGiftView';
import ProductReviewDetails from './Components/Products/ProductReviewDetails';
import CountryManage from './Components/CountryManage/CountryManage';
import CountryView from './Components/CountryManage/CountryView';
import CountryEdit from './Components/CountryManage/CountryEdit';
import UserStateManage from './Components/CountryManage/UserStateManage';
import NotifyEmails from './Components/Notify/NotifyEmails';
import NotifyView from './Components/Notify/NotifyView';
import CorporateImages from './Components/CorporateGift/CorporateImages';
import CorporateImageAdd from './Components/CorporateGift/CorporateImageAdd';
import CorporateImageView from './Components/CorporateGift/CorporateImageView';
import CorporateImageEdit from './Components/CorporateGift/CorporateImageEdit';
import BottomBanner from './Components/Herosection/BottomBanner';
import Invoive from './Components/Orders/Invoive';
function App() {
  return (
    <>
      {/* <h1>!! Jai Shree Ram !!</h1> */}
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/verify-otp' element={<VerifyOtp />} />
          <Route path='/:id/reset-password' element={<ResetPassword />} />
          <Route path='/expirelink' element={<ExpireLink />} />
          <Route path='/invoice/:id' element={<ProtectedRoute component={Invoive} />} />
          <Route path='/' element={<ProtectedRoute component={Layout} />}>
            <Route index element={<ProtectedRoute component={Home} />} />
            {/* products */}
            <Route path='/productlist' element={<ProtectedRoute component={ProductList} />} />
            <Route path='/addproduct' element={<ProtectedRoute component={AddProduct} />} />
            <Route path='/editproduct/:id' element={<ProtectedRoute component={EditProduct} />} />
            <Route path='/productview/:id' element={<ProtectedRoute component={ProductView} />} />
            <Route path='/productreview' element={<ProtectedRoute component={ProductReviews} />} />
            <Route path='/reviewdetails/:id' element={<ProtectedRoute component={ProductReviewDetails} />} />
            {/* category */}
            <Route path='/categorylist' element={<ProtectedRoute component={CategoryList} />} />
            <Route path='/addcategory' element={<ProtectedRoute component={AddCategory} />} />
            <Route path='/editcategory/:id' element={<ProtectedRoute component={EditCategory} />} />
            <Route path='/categoryview/:id' element={<ProtectedRoute component={CategoryView} />} />
            {/* sub-category */}
            <Route path='/subcategorylist' element={<ProtectedRoute component={SubCategoryList} />} />
            <Route path='/addsubcategory' element={<ProtectedRoute component={AddSubCategory} />} />
            <Route path='/editsubcategory/:id' element={<ProtectedRoute component={EditSubCategory} />} />
            <Route path='/subcategoryview/:id' element={<ProtectedRoute component={SubCategoryView} />} />
            {/* brands */}
            <Route path='/brands' element={<ProtectedRoute component={Brands} />} />
            <Route path='/addbrands' element={<ProtectedRoute component={AddBrands} />} />
            <Route path='/editbrands/:id' element={<ProtectedRoute component={EditBrands} />} />
            <Route path='/brandsview/:id' element={<ProtectedRoute component={BrandView} />} />
            {/* variants */}
            <Route path='/variants' element={<ProtectedRoute component={Variants} />} />
            <Route path='/addvariants' element={<ProtectedRoute component={AddVariants} />} />
            <Route path='/editvariants/:id' element={<ProtectedRoute component={EditVariants} />} />
            <Route path='/variantsview/:id' element={<ProtectedRoute component={VariantView} />} />
            <Route path='/variantvalueslist/:id' element={<ProtectedRoute component={VariantValuesList} />} />
            <Route path='/addvalues/:id' element={<ProtectedRoute component={AddVariantsValues} />} />
            <Route path='/variantvaluesview/:id' element={<ProtectedRoute component={VariantValueView} />} />
            <Route path='/editvariantvalues/:id' element={<ProtectedRoute component={EditVariantValue} />} />
            {/* units */}
            <Route path='/units' element={<ProtectedRoute component={UnitList} />} />
            <Route path='/addunits' element={<ProtectedRoute component={AddUnits} />} />
            <Route path='/unitview/:id' element={<ProtectedRoute component={UnitsView} />} />
            <Route path='/editunits/:id' element={<ProtectedRoute component={EditUnit} />} />
            {/* users and staff */}
            <Route path='/userlist' element={<ProtectedRoute component={UserList} />} />
            <Route path='/userdetails/:id' element={<ProtectedRoute component={UserListView} />} />
            <Route path='/useredit/:id' element={<ProtectedRoute component={EditUser} />} />

            <Route path='/staff' element={<ProtectedRoute component={TeamMember} />} />
            <Route path='/addstaff' element={<ProtectedRoute component={AddStaff} />} />
            <Route path='/staffdetails/:id' element={<ProtectedRoute component={StaffView} />} />
            <Route path='/staffedit/:id' element={<ProtectedRoute component={EditStaff} />} />

            {/* roles and permission */}
            <Route path='/rolelist' element={<ProtectedRoute component={RoleList} />} />
            <Route path='/addrole' element={<ProtectedRoute component={AddRole} />} />
            <Route path='/roledetails/:id' element={<ProtectedRoute component={RoleView} />} />
            <Route path='/roleedit/:id' element={<ProtectedRoute component={EditRole} />} />

            {/* profile */}
            <Route path='/profile' element={<ProtectedRoute component={Profile} />} />
            <Route path='/changepassword' element={<ProtectedRoute component={ChangePassword} />} />

            {/* orders */}
            <Route path='/order' element={<ProtectedRoute component={Order} />} />
            <Route path='/orderview/:id' element={<ProtectedRoute component={OrderView} />} />
          

            {/* coupons */}
            <Route path='/couponlist' element={<ProtectedRoute component={CouponList} />} />
            <Route path='/addcoupon' element={<ProtectedRoute component={AddCoupon} />} />
            <Route path='/couponview/:id' element={<ProtectedRoute component={CouponView} />} />
            <Route path='/editcoupon/:id' element={<ProtectedRoute component={EditCoupon} />} />

            {/* home */}
            <Route path='/bannerslider' element={<ProtectedRoute component={BannerSlider} />} />
            <Route path='/addbannerslider' element={<ProtectedRoute component={Hero} />} />
            <Route path='/bannersliderview/:id' element={<ProtectedRoute component={BannerSliderView} />} />
            <Route path='/editbannerslider/:id' element={<ProtectedRoute component={EditBannerSlider} />} />
            {/* Bottom banner */}
            <Route path='/bottombanner' element={<ProtectedRoute component={BottomBanner} />} />
            <Route path='/sale' element={<ProtectedRoute component={Sale} />} />
            <Route path='/advertisement' element={<ProtectedRoute component={Advertisement} />} />
            {/* cms management */}
            <Route path='/cmslist' element={<ProtectedRoute component={CmsList} />} />
            <Route path='/addcms' element={<ProtectedRoute component={AddCms} />} />
            <Route path='/cmsview/:id' element={<ProtectedRoute component={CmsView} />} />
            <Route path='/Editcms/:id' element={<ProtectedRoute component={EditCms} />} />

            {/* faq */}
            <Route path='/faqlist' element={<ProtectedRoute component={FaqList} />} />
            <Route path='/addfaq' element={<ProtectedRoute component={AddFaq} />} />
            <Route path='/faqview/:id' element={<ProtectedRoute component={FaqView} />} />
            <Route path='/editfaq/:id' element={<ProtectedRoute component={EditFaq} />} />
            <Route path='/subscribers' element={<ProtectedRoute component={Subscribers} />} />
            <Route path='/settings' element={<ProtectedRoute component={Settings} />} />
            <Route path='/contacts' element={<ProtectedRoute component={Contacts} />} />

            {/* state Management */}
            <Route path='/statemanage' element={<ProtectedRoute component={StateManage} />} />
            <Route path='/stateview/:id' element={<ProtectedRoute component={StateView} />} />
            <Route path='/editstate/:id' element={<ProtectedRoute component={StateEdit} />} />

            {/* country Management */}
            <Route path='/countrymanage' element={<ProtectedRoute component={CountryManage} />} />
            <Route path='/userstatemanage/:id' element={<ProtectedRoute component={UserStateManage} />} />
            <Route path='/countryview/:id' element={<ProtectedRoute component={CountryView} />} />
            <Route path='/editcountry/:id' element={<ProtectedRoute component={CountryEdit} />} />

            {/* corporate gift Management */}
            <Route path='/corporategiftlist' element={<ProtectedRoute component={CorporateGift} />} />
            <Route path='/corporategiftview/:id' element={<ProtectedRoute component={CorporateGiftView} />} />
            <Route path='/corporateimagelist' element={<ProtectedRoute component={CorporateImages} />} />
            <Route path='/corporateimageadd' element={<ProtectedRoute component={CorporateImageAdd} />} />
            <Route path='/giftview/:id' element={<ProtectedRoute component={CorporateImageView} />} />
            <Route path='/editgifts/:id' element={<ProtectedRoute component={CorporateImageEdit} />} />

            {/* notify management */}
            <Route path='/notifyemails' element={<ProtectedRoute component={NotifyEmails} />} />
            <Route path='/notifyview/:id' element={<ProtectedRoute component={NotifyView} />} />

            <Route path='/addstock' element={<ProtectedRoute component={AddStock} />} />
            <Route path='/locations' element={<ProtectedRoute component={AllLocations} />} />
            <Route path='/addlocations' element={<ProtectedRoute component={AddLocation} />} />
            <Route path='/deliverymens' element={<ProtectedRoute component={Deliverymens} />} />
            <Route path='/adddeliverymen' element={<ProtectedRoute component={AddDeliveryMen} />} />
            <Route path='/cancelrequest' element={<ProtectedRoute component={CancelRequest} />} />
            <Route path='/configurations' element={<ProtectedRoute component={Configurations} />} />
            <Route path='/deliverymenpayroll' element={<ProtectedRoute component={DeliverMenPayroll} />} />
            <Route path='/deliverymenpayrolllist' element={<ProtectedRoute component={DeliveryMenPayrollList} />} />
            <Route path='/payouthistory' element={<ProtectedRoute component={PayoutHistory} />} />
            <Route path='/orderreport' element={<ProtectedRoute component={OrderReport} />} />
            <Route path='/productsalereport' element={<ProtectedRoute component={ProductSalesReport} />} />
            <Route path='/categorywisereport' element={<ProtectedRoute component={CategoryWiseSale} />} />
            <Route path='/salesamountreport' element={<ProtectedRoute component={SalesAmountReport} />} />
            <Route path='/deliverystatusreport' element={<ProtectedRoute component={DeliveryStatusReport} />} />
            <Route path='/queries' element={<ProtectedRoute component={Queries} />} />
            <Route path='/refundconfigurations' element={<ProtectedRoute component={RefundConfigurations} />} />
            <Route path='/refundrequests' element={<ProtectedRoute component={RefundRequests} />} />
            <Route path='/approvedrefunds' element={<ProtectedRoute component={ApprovedRefunds} />} />
            <Route path='/rejectsrefunds' element={<ProtectedRoute component={RejectedRefunds} />} />
            <Route path='/setrewardpoints' element={<ProtectedRoute component={SetRewardPoints} />} />
            <Route path='/rewardsconfigurations' element={<ProtectedRoute component={RewardConfigurations} />} />
            <Route path='/walletconfigurations' element={<ProtectedRoute component={WalletConfigurations} />} />

            <Route path='/shippingzone' element={<ProtectedRoute component={ShippingZone} />} />
            <Route path='/addzone' element={<ProtectedRoute component={AddZone} />} />
          </Route>
          <Route path='*' element={< PageNotFound />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer autoClose={3000} />
    </>
  );
}

export default App;
