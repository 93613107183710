import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
const CorporateGift = () => {
    const [giftList, setGiftList] = useState([]);
    const [loading, setloading] = useState(false)
    useEffect(() => {
        fetchGifts();
    }, [])

    // fetch category api
    const fetchGifts = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/custom-quote-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("gift", res.data.data)
            setGiftList(res.data.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("fetch gift error", error)
        }
    }

    // delete api
    const handleCategoryDelete = async (catId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    const res = await axios.delete(`${baseUrl}/category-delete/${catId}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    })
                    if (res.data.status) {
                        toast.success(res.data.message)
                        setGiftList(prevCat => prevCat.filter(cat => cat._id !== catId));
                    } else {
                        toast.error(res.data.message)
                    }

                } catch (error) {
                    console.log('error delete subcategory', error)
                }
            }
        })

    }
    const handleToggleStatus = async (catId, currentStatus) => {
        try {
            setloading(true)
            const newStatus = !currentStatus;
            const res = await axios.post(`${baseUrl}/category-status-change/${catId}`,
                { status: newStatus },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
            console.log(res);
            if (res.data.status) {
                toast.success(res.data.message)
                setloading(false)
                setGiftList(prevCat =>
                    prevCat.map(cat =>
                        cat._id === catId ? { ...cat, status: newStatus } : cat
                    )
                )
            }
        } catch (error) {
            setloading(false)
            console.log("toggle category status error", error)
        }
    }
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main">
                            <h4 className="text-capitalize breadcrumb-title">Corporate Gift list</h4>
                            <div className="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"><i className="uil uil-estate"></i>Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Corporate Gift  list</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                                    <div className="table-responsive">
                                        <table className="table mb-0 table-borderless">
                                            <thead>
                                                <tr className="userDatatable-header">
                                                    <th><span className="userDatatable-title">S.No.</span></th>
                                                    <th>
                                                        <span className="userDatatable-title">
                                                          Name
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">
                                                          Company/Email
                                                        </span>
                                                    </th>
                                                    {/* <th>
                                                        <span className="userDatatable-title">Email</span>
                                                    </th> */}
                                                    <th>
                                                        <span className="userDatatable-title">Location</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Total Boxes</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Comments</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Ceated At</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title ">
                                                            action
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    giftList.map((cat, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <div className="userDatatable-content">{index + 1}</div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-inline-title">
                                                                        <h6>{cat.name}</h6>
                                                                        <div style={{ fontSize: "12px", lineHeight: 0 }}>
                                                                            ({cat.mobile})
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-inline-title">
                                                                        <h6>{cat.company_name}</h6>
                                                                        <div style={{ fontSize: "12px", lineHeight: 0 }}>
                                                                           ({cat.email})
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                {/* <td>
                                                                    <div className="userDatatable-inline-title">
                                                                        <h6>{cat.mobile}</h6>
                                                                    </div>
                                                                </td> */}
                                                                {/* <td>
                                                                    <div className="userDatatable-inline-title">
                                                                        <h6>{cat.email}</h6>
                                                                    </div>
                                                                </td> */}
                                                                <td>
                                                                    <div className="userDatatable-inline-title">
                                                                        <h6>{cat.location}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-inline-title">
                                                                        <h6>{cat.boxes_number}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-inline-title">
                                                                        <h6>{cat.comments}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-inline-title">
                                                                        {new Date(cat.createdAt).toLocaleString()}  
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <ul className="orderDatatable_actions">
                                                                        <li>
                                                                            <Link to={`/corporategiftview/${cat._id}`} className="view">
                                                                                <i className="uil uil-eye" />
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CorporateGift
