import React, { useEffect, useState } from 'react'
import ekologo from '../../assets/img/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import baseUrl from '../Helper/BaseUrl';
import { toast } from 'react-toastify';

function VerifyOtp() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        otp: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const email = sessionStorage.getItem('resetEmail');

    useEffect(() => {
        if (!email) {
            navigate('/forgot-password');
            toast.error('Please provide email first');
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const validateForm = () => {
        const toastId = 'otp-Id';
        if (!formData.otp) {
            if (!toast.isActive(toastId)) {
                toast.error('Please enter OTP', { toastId: toastId });
            }
            return false;
        }
        if (!formData.newPassword) {
            if (!toast.isActive(toastId)) {
                toast.error('Please enter new password', { toastId: toastId });
            }
            return false;
        }
        if (!formData.confirmPassword) {
            if (!toast.isActive(toastId)) {
                toast.error('Please enter confirm Password', { toastId: toastId });
            }
            return false;
        }
        if (formData.newPassword.length < 6) {
            if (!toast.isActive(toastId)) {
                toast.error('Password must be at least 6 characters', { toastId: toastId });
            }
            return false;
        }
        if (formData.newPassword !== formData.confirmPassword) {
            if (!toast.isActive(toastId)) {
                toast.error('Passwords do not match', { toastId: toastId });
            }
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true);
            try {
                const response = await axios.post(`${baseUrl}/verify-otp-forget-password`, {
                    email: email,
                    otp: formData.otp,
                    password: formData.newPassword
                });

                if (response.data.status) {
                    toast.success(response.data.message);
                    sessionStorage.removeItem('resetEmail');
                    navigate('/login');
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error(error.response?.data?.message || 'Verification failed');
            } finally {
                setLoading(false);
            }
        }
    };
    const handleResendOtp = async () => {
        try {
            const response = await axios.post(`${baseUrl}/email-otp-send`, {
                email: email
            });
            if (response.data.status) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Failed to resend OTP');
        }
    };
    return (
        <>
            <main className="main-content login-sec">
                <div className="admin">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-xxl-4 col-xl-4 col-md-6 col-sm-8">
                                <div className="edit-profile">
                                    <div className="edit-profile__logos">
                                        <img className="dark" src={ekologo} alt="logodark" />
                                        <img className="light" src={ekologo} alt="logolight" />
                                    </div>
                                    <div className="card border-0">
                                        <div className="card-header">
                                            <div className="edit-profile__title">
                                                <h6>Verify OTP and Set Password</h6>
                                            </div>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div className="card-body">
                                                <div className="edit-profile__body">
                                                    <div className="form-group">
                                                        <label htmlFor="username">
                                                            OTP
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="otp"
                                                            value={formData.otp}
                                                            onChange={handleChange}
                                                            maxLength={6}
                                                            className="form-control"
                                                            id=""
                                                            placeholder="Enter Otp"
                                                        />
                                                        <div className='float-end mt-2'>
                                                            <button type='button' className='btn btn-success btn-sm fs-12 d-block'
                                                                onClick={handleResendOtp}
                                                            >Resend OTP</button>
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="password-field">New Password</label>
                                                        <div className="position-relative">
                                                            <input
                                                                type={showPassword ? "text" : "password"}
                                                                className="form-control"
                                                                name="newPassword"
                                                                value={formData.newPassword}
                                                                onChange={handleChange}
                                                                placeholder="enter password"
                                                            />
                                                            <div
                                                                className={`uil ${showPassword ? 'uil-eye' : 'uil-eye-slash'} text-lighten fs-15 field-icon`}
                                                                onClick={() => setShowPassword(!showPassword)}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="password-field">Confirm New Password</label>
                                                        <div className="position-relative">
                                                            <input
                                                                id="password-field"
                                                                type={showConfirmPassword ? "text" : "password"}
                                                                className="form-control"
                                                                name="confirmPassword"
                                                                value={formData.confirmPassword}
                                                                onChange={handleChange}
                                                                placeholder="Confirm new password"
                                                            />
                                                            <div
                                                                className={`uil ${showConfirmPassword ? 'uil-eye' : 'uil-eye-slash'} text-lighten fs-15 field-icon`}
                                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                    <div className="admin-condition justify-content-end">
                                                        {/* <Link to="/forgot-password">forget password?</Link> */}
                                                    </div>
                                                    <div className="admin__button-group button-group d-flex pt-1 justify-content-md-start justify-content-center mt-4">
                                                        <button type='submit' disabled={loading} className="btn btn-primary btn-default w-100 signIn-createBtn ">
                                                            {loading ? (
                                                                <span className="spinner-border spinner-border-sm"></span>
                                                            ) : (
                                                                "Submit"
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default VerifyOtp