import React, { useEffect, useState } from 'react'
import searchImg from "../../assets/img/svg/search.svg";
import axios from 'axios';
import baseUrl from '../Helper/BaseUrl';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const RoleList = () => {
    const [rolelist, setRoleList] = useState([])
    useEffect(() => {
        fetchRoles();
    }, [])
    const [loading, setLoading] = useState(false)
    const fetchRoles = async () => {
        setLoading(true)
        try {
            const res = await axios.get(`${baseUrl}/role-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log(res)
            setRoleList(res.data.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log("error fetch role list", error)
        }
    }


    //  handle status change
    const handleStatusToggle = async (roleId, currentStatus) => {
        try {
            // setloading(true)
            const newStatus = !currentStatus;
            const response = await axios.get(
                `${baseUrl}/role-status-change/${roleId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );
            // console.log(response)
            if (response.data.status) {
                toast.success(response.data.message)
                // setloading(false)
                setRoleList(prevState =>
                    prevState.map(role =>
                        role._id === roleId ? { ...role, status: newStatus } : role
                    )
                );
            }
        } catch (error) {
            // setloading(false)
            console.error('Error changing user status:', error);
        }
    };


    // delete api
    const handleRoleDelete = async (roleId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await axios.delete(`${baseUrl}/role-delete/${roleId}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    })
                    if (res.data.status) {
                        toast.success(res.data.message)
                        setRoleList(prevUnit => prevUnit.filter(role => role._id !== roleId));
                    } else {
                        toast.error(res.data.message)
                    }

                } catch (error) {
                    console.log('error delete role', error)
                }
            }
        })

    }
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>)}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main">
                            <h4 className="text-capitalize breadcrumb-title">Role list</h4>
                            <div className="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"><i className="uil uil-estate"></i>Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Role list</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header d-flex justify-content-end">
                                {/* <form
                                    action=""
                                    className="user-member__form"
                                >
                                    <div className='with-icon'>
                                        <span className="uil uil-search"></span>
                                        <input
                                            className="form-control"
                                            type="search"
                                            placeholder="Search by Name"
                                            aria-label="Search"
                                        //   onChange={(e) => setSearchText(e.target.value)}
                                        />
                                    </div>
                                </form> */}
                                <Link className="btn btn-primary" to="/addrole">
                                    <span className="uil uil-plus"></span>Add Role
                                </Link>
                            </div>
                            <div className="card-body">
                                <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                                    <div className="table-responsive">
                                        <table className="table mb-0 table-borderless table-striped">
                                            <thead>
                                                <tr className="userDatatable-header">
                                                    <th>
                                                        <span className="userDatatable-title">
                                                            S. No.
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">
                                                            role name
                                                        </span>
                                                    </th>
                                                    {/* <th>
                                              <span className="userDatatable-title">status</span>
                                          </th> */}
                                                    <th>
                                                        <span className="userDatatable-title float-end">
                                                            action
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    rolelist && rolelist.map((role, index) => {
                                                        return (
                                                            <tr key={role._id}>
                                                                <td>
                                                                    <div className="userDatatable-content">
                                                                        {/* {++index + 10 * (page - 1)} */}
                                                                        {index + 1}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex">
                                                                        <div className="userDatatable-inline-title">
                                                                            <h6>{role.role_name}</h6>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                {/* <td>
                                                          <div className="userDatatable-content d-inline-block">
                                                              <div className="form-check form-switch">
                                                                  <input className="form-check-input" checked={role?.status} onChange={() => handleStatusToggle(role._id, role.status)} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                              </div>
                                                          </div>
                                                      </td> */}
                                                                <td>
                                                                    <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                                        <li>
                                                                            <Link to={`/roledetails/${role._id}`} className="view">
                                                                                <i className="uil uil-eye" />
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to={`/roleedit/${role._id}`} className="edit">
                                                                                <i className="uil uil-edit" />
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <a type="button" onClick={() => handleRoleDelete(role._id)} className="remove">
                                                                                <i className="uil uil-trash-alt" />
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RoleList