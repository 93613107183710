import React, { useEffect, useState } from 'react'
import media from "../../assets/img/food-restaurant-media.png"
import upload from "../../assets/img/svg/upload.svg"
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const StateEdit = () => {
    const { id } = useParams();
    const [values, setValues] = useState({
        name: "",
        status: "",
        sequence: "",
    })
    const [imgFile, setImgFile] = useState(null)
    const [loading, setloading] = useState(false)
    const [imagePreview, setImagePreview] = useState("")
    const navigate = useNavigate()
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(preVal => ({
            ...preVal,
            [name]: value
        }))
    }
    // category view api
    const fetchSingleStates = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/state-view/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            setValues(res.data.data)
            setImgFile(res.data.data.image)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("single category error", error)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('name', values.name)
            formData.append('status', values.status)
            formData.append('sequence', values.sequence)
            if (imgFile) {
                formData.append('image', imgFile)
            }
            const res = await axios.post(`${baseUrl}/state-update/${id}`, formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
            if (res.data.message) {
                toast.success(res.data.message)
                navigate('/statemanage')
            } else {
                toast.error(res.data.error)
            }
        } catch (error) {
            console.log("error edit state", error)
        }
    }

    useEffect(() => {
        fetchSingleStates();
    }, [])

    useEffect(() => {
        if (imgFile instanceof File) {
            setImagePreview(URL.createObjectURL(imgFile))
            return () => URL.revokeObjectURL(imagePreview)
        }
    }, [imgFile])
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    Edit State
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Edit State
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="add-product__body px-sm-40 px-20">
                                    <form onSubmit={handleSubmit}>
                                        <div className='form-basic'>
                                            <div className='row'>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">State name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='name'
                                                            value={values?.name}
                                                            onChange={handleChange}
                                                            placeholder="enter state name"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <div className="countryOption">
                                                            <label htmlFor="countryOption">Status</label>
                                                            <select
                                                                className="js-example-basic-single js-states form-control"
                                                                id="countryOption"
                                                                name='is_active'
                                                                value={values?.is_active}
                                                                onChange={handleChange}
                                                            >
                                                                <option value="">Select Status</option>
                                                                <option value={0}>Active</option>
                                                                <option value={1}>Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name8">sequence</label>
                                                        <input
                                                            type="number"
                                                            name='sequence'
                                                            value={values?.sequence}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            id="name8"
                                                            placeholder="sequence"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 mb-3">

                                                    <h6 className="fw-500">State image (size in pixels(228*207))</h6>
                                                    <label
                                                        htmlFor="upload"
                                                        className="file-upload__label"
                                                    >
                                                        <span className="upload-product-img px-10 d-block">
                                                            <span className="file-upload">
                                                                <img
                                                                    className="svg"
                                                                    src={upload}
                                                                    alt=""
                                                                />
                                                                <input
                                                                    id="upload"
                                                                    className="file-upload__input"
                                                                    type="file"
                                                                    name="file-upload"
                                                                    onChange={(e) => setImgFile(e.target.files[0])}
                                                                />
                                                            </span>
                                                        </span>
                                                    </label>
                                                    <div className="upload-media-area">
                                                        {imgFile instanceof File ? (
                                                            <img
                                                                src={imagePreview}
                                                                alt="Preview"
                                                            />
                                                        ) : (
                                                            imgFile && (
                                                                <img
                                                                    src={`${imgUrl}/${imgFile}`}
                                                                    alt="Current State"
                                                                    className="img-fluid"
                                                                />
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="button-group mt-1 add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                    <button type='submit' className="btn btn-primary btn-default btn-squared text-capitalize">
                                                        save State
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default StateEdit

