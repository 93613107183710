import React, { useEffect, useState } from 'react'
import upload from "../../assets/img/svg/upload.svg"
import media from "../../assets/img/food-restaurant-media.png"
import trash from "../../assets/img/svg/trash-2.svg"
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
const EditBannerSlider = () => {
    const {id} = useParams()
    const [values, setValues] = useState({
        slider_link: "",
        status: "",
    })
    const [imgFile, setImgFile] = useState(null)
    const [loading, setloading] = useState(false)
    const [imgLoading, setImgLoading] = useState(false)

    const [currentImage, setCurrentImage] = useState("")
    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('slider_link', values.slider_link)
            formData.append('status', values.status)
            if (imgFile) {
                formData.append('image', imgFile)
            }
            const res = await axios.post(`${baseUrl}/hero-slider-update/${id}`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("****",res)
            if (res.data.status) {
                toast.success(res.data.message)
                navigate('/bannerslider')
            } else {
                toast.error(res.data.message)
            }
        } catch (error) {
            console.log("edit banner slider error", error)
        }
    }

    useEffect(() => {
        fetchSingleBannerSlider();
    }, [])

    // // fetch sub category
    const fetchSingleBannerSlider = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/hero-slider-view/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("banner", res.data.data)
            setValues(res.data.data)
            setCurrentImage(res.data.data.image)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("fetch banner slider error", error)
        }
    }

    const handleImageChange = (e) => {
        let file = e.target.files[0];
        if (file) {
            setImgLoading(true)
            setImgFile(file);
            const timeout = setTimeout(() => {
                setImgLoading(false)
            }, 1000);
        }

    }
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    Edit Banner
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Banner Configuration
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="add-product__body px-sm-40 px-20">
                                    <form onSubmit={handleSubmit}>
                                        <div className='form-basic'>
                                            <div className='row'>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name2">slider Link</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name2"
                                                            name='slider_link'
                                                            value={values.slider_link}
                                                            onChange={handleChange}
                                                            placeholder="Link"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <div className="countryOption">
                                                            <label htmlFor="countryOption">Status</label>
                                                            <select
                                                                className="js-example-basic-single js-states form-control"
                                                                id="countryOption"
                                                                name='status'
                                                                value={values?.status}
                                                                onChange={handleChange}
                                                            >
                                                                <option value="">Select Status</option>
                                                                <option value="true">Active</option>
                                                                <option value="false">Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 mb-3">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-500">Banner image (size in pixels(1294*453))</h6>
                                                        <label
                                                            htmlFor="upload"
                                                            className="file-upload__label"
                                                        >
                                                            <span className="upload-product-img px-10 d-block">
                                                                <span className="file-upload">
                                                                    <img
                                                                        className="svg"
                                                                        src={upload}
                                                                        alt=""
                                                                    />
                                                                    <input
                                                                        id="upload"
                                                                        className="file-upload__input"
                                                                        type="file"
                                                                        name="file-upload"
                                                                        onChange={handleImageChange}
                                                                    />
                                                                </span>
                                                            </span>
                                                        </label>
                                                        <div className="upload-product-media d-flex justify-content-between align-items-center mt-25">
                                                            <div className="upload-media-area">
                                                               {
                                                                 imgLoading ? (
                                                                        <span className="spinner-border spinner-border-sm"></span>
                                                                 ):(
                                                                     imgFile? (
                                                                                <img
                                                                            src = {URL.createObjectURL(imgFile)}
                                                                alt="img"
                                                                        />
                                                                ) : (
                                                                currentImage && (
                                                                <img
                                                                    src={`${imgUrl}/${currentImage}`}
                                                                    alt="Current Category"
                                                                    className="img-fluid"
                                                                />
                                                                )
                                                                )
                                                                 )
                                                               }
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="button-group add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                    <button type='submit' className="btn btn-primary btn-default btn-squared text-capitalize">
                                                        save banner
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default EditBannerSlider
