import React, { useEffect, useState } from 'react'
import upload from "../../assets/img/svg/upload.svg"
import media from "../../assets/img/food-restaurant-media.png"
import trash from "../../assets/img/svg/trash-2.svg"
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
const BannerSliderView = () => {
    const { id } = useParams();
    const [values, setValues] = useState({
        slider_link: "",
        status: "",
    })
    const [imgFile, setImgFile] = useState(null)
    const [loading, setloading] = useState(false)
    useEffect(() => {
        fetchSingleBannerSlider();
    }, [])

    // // fetch sub category
    const fetchSingleBannerSlider = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/hero-slider-view/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("banner", res.data.data)
            setValues(res.data.data)
            setImgFile(res.data.data.image)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("fetch banner slider error", error)
        }
    }

    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    <li className="breadcrumb-item ">
                                        <Link to="/bannerslider">
                                            <i className="fa-solid fa-circle-arrow-left"></i>
                                            <span>Back</span>
                                        </Link>
                                    </li>
                                    / Banner Details
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Banner Configuration
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="add-product__body px-sm-40 px-20">
                                    <form >
                                        <div className='form-basic'>
                                            <div className='row'>
                                                {/* <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">Main Heading</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='main_heading'
                                                            value={values.main_heading}
                                                            disabled
                                                            placeholder="enter main heading"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name2">Sub Heading</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name2"
                                                            name='sub_heading'
                                                            value={values.sub_heading}
                                                            disabled
                                                            placeholder="emter sub heading"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name2">Button Heading</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name2"
                                                            name='button_heading'
                                                            value={values.button_heading}
                                                            disabled
                                                            placeholder="enter button heading"
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name2">slider Link</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name2"
                                                            name='slider_link'
                                                            value={values.slider_link}
                                                            disabled
                                                            placeholder="Link"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <div className="countryOption">
                                                            <label htmlFor="countryOption">Status</label>
                                                            <select
                                                                className="js-example-basic-single js-states form-control"
                                                                id="countryOption"
                                                                name='status'
                                                                value={values?.status}
                                                                disabled
                                                            >
                                                                <option value="">Select Status</option>
                                                                <option value="true">Active</option>
                                                                <option value="false">Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 mb-3">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-500">Banner image</h6>
                                                        <div className="upload-product-media d-flex justify-content-between align-items-center mt-25">
                                                            <div className="upload-media-area">
                                                                {
                                                                    imgFile && (
                                                                        <img
                                                                            src={`${imgUrl}/${imgFile}`}
                                                                            alt="img"
                                                                        />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BannerSliderView
