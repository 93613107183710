import React, { useEffect, useState } from 'react'
import axios from 'axios'
import baseUrl from './Helper/BaseUrl'
import { Link } from 'react-router-dom'

const Home = () => {
  const [dash, setDash] = useState({});
  useEffect(() => {
    fetchDashBoard();
  }, [])

  const fetchDashBoard = async () => {
    try {
      const res = await axios.get(`${baseUrl}/dashboard`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      console.log("res", res)
      setDash(res.data.data)
    } catch (error) {
      console.log("error fetch dashboard data", error)
    }
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-lg-12">
            <div className="breadcrumb-main">
              <h4 className="text-capitalize breadcrumb-title">Dashboard</h4>
              <div className="breadcrumb-action justify-content-center flex-wrap">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="uil uil-estate" />
                        Dashboard
                      </Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className="col-xxl-3 col-sm-6 mb-25">
            <div className="ap-po-details ap-po-details--2 p-25 radius-xl d-flex justify-content-between">
              <div className="overview-content w-100">
                <Link to='/productlist'>
                  <div className=" ap-po-details-content d-flex flex-wrap justify-content-between">
                    <div className="ap-po-details__titlebar">
                      <h1>{dash.productCount}+</h1>
                      <p>Total Product</p>
                    </div>
                    <div className="ap-po-details__icon-area">
                      <div className="svg-icon order-bg-opacity-secondary color-secondary">
                        <i className="uil uil-users-alt" />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-sm-6 mb-25">
            <Link to='/couponlist'>
              <div className="ap-po-details ap-po-details--2 p-25 radius-xl d-flex justify-content-between">
                <div className="overview-content w-100">
                  <div className=" ap-po-details-content d-flex flex-wrap justify-content-between">
                    <div className="ap-po-details__titlebar">
                      <h1>{dash.couponsCount}+</h1>
                      <p>All coupons</p>
                    </div>
                    <div className="ap-po-details__icon-area">
                      <div className="svg-icon order-bg-opacity-info color-info">
                        <i className="uil uil-users-alt" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-lg-12'>
            <h4 className="text-capitalize breadcrumb-title mb-4">Total Users</h4>
          </div>
          <div className="col-xxl-3 col-sm-6 mb-25">
            <div className="ap-po-details ap-po-details--2 p-25 radius-xl d-flex justify-content-between">
              <div className="overview-content w-100">
                <Link to='/userlist'>
                  <div className=" ap-po-details-content d-flex flex-wrap justify-content-between">
                    <div className="ap-po-details__titlebar">
                      <h1>{dash.usersCount}+</h1>
                      <p>Total Users</p>
                    </div>
                    <div className="ap-po-details__icon-area">
                      <div className="svg-icon order-bg-opacity-secondary color-secondary">
                        <i className="uil uil-users-alt" />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-sm-6 mb-25">
            <div className="ap-po-details ap-po-details--2 p-25 radius-xl d-flex justify-content-between">
              <Link to='/userlist'>
                <div className="overview-content w-100">
                  <div className=" ap-po-details-content d-flex flex-wrap justify-content-between">
                    <div className="ap-po-details__titlebar">
                      <h1>100+</h1>
                      <p>New Users</p>
                    </div>
                    <div className="ap-po-details__icon-area">
                      <div className="svg-icon order-bg-opacity-primary color-primary">
                        <i className="uil uil-user-square" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xxl-3 col-sm-6 mb-25">
            <div className="ap-po-details ap-po-details--2 p-25 radius-xl d-flex justify-content-between">
              <Link to='/userlist'>
                <div className="overview-content w-100">
                  <div className=" ap-po-details-content d-flex flex-wrap justify-content-between">
                    <div className="ap-po-details__titlebar">
                      <h1>100+</h1>
                      <p>Recent Users</p>
                    </div>
                    <div className="ap-po-details__icon-area">
                      <div className="svg-icon order-bg-opacity-info color-info">
                        <i className="uil uil-user-circle" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xxl-3 col-sm-6 mb-25">
            <div className="ap-po-details ap-po-details--2 p-25 radius-xl d-flex justify-content-between">
              <Link to='/userlist'>
                <div className="overview-content w-100">
                  <div className=" ap-po-details-content d-flex flex-wrap justify-content-between">
                    <div className="ap-po-details__titlebar">
                      <h1>100+</h1>
                      <p>Active Users</p>
                    </div>
                    <div className="ap-po-details__icon-area">
                      <div className="svg-icon order-bg-opacity-warning color-warning">
                        <i className="uil  uil-user-check" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

        </div>
        <div className='row mt-5'>
          <div className='col-lg-12'>
            <h4 className="text-capitalize breadcrumb-title mb-4">Orders ({dash.ordersCount})</h4>
          </div>
          <div className="col-xxl-3 col-sm-6 mb-5">
            <div className="ap-po-details ap-po-details--2 p-25 radius-xl d-flex justify-content-between">
              <Link to='/order'>
                <div className="overview-content w-100">
                  <div className=" ap-po-details-content d-flex flex-wrap justify-content-between">
                    <div className="ap-po-details__titlebar">
                      <h1>{dash.ordersCount}+</h1>
                      <p>Total Order</p>
                    </div>
                    <div className="ap-po-details__icon-area">
                      <div className="svg-icon order-bg-opacity-primary color-primary">
                        <i className="uil uil-shopping-cart-alt" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xxl-3 col-sm-6 mb-5">
            <div className="ap-po-details ap-po-details--2 p-25 radius-xl d-flex justify-content-between">
              <Link to='/order'>
                <div className="overview-content w-100">
                  <div className=" ap-po-details-content d-flex flex-wrap justify-content-between">
                    <div className="ap-po-details__titlebar">
                      <h1>100+</h1>
                      <p>Packaged Order</p>
                    </div>
                    <div className="ap-po-details__icon-area">
                      <div className="svg-icon order-bg-opacity-warning color-warning">
                        <i className="uil uil-shopping-cart" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xxl-3 col-sm-6 mb-5">
            <div className="ap-po-details ap-po-details--2 p-25 radius-xl d-flex justify-content-between">
              <Link to='/order'>
                <div className="overview-content w-100">
                  <div className=" ap-po-details-content d-flex flex-wrap justify-content-between">
                    <div className="ap-po-details__titlebar">
                      <h1>100+</h1>
                      <p>Return Order</p>
                    </div>
                    <div className="ap-po-details__icon-area">
                      <div className="svg-icon order-bg-opacity-primary color-primary">
                        <i className="uil uil-sync" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xxl-3 col-sm-6 mb-5">
            <div className="ap-po-details ap-po-details--2 p-25 radius-xl d-flex justify-content-between">
              <Link to='/order'>
                <div className="overview-content w-100">
                  <div className=" ap-po-details-content d-flex flex-wrap justify-content-between">
                    <div className="ap-po-details__titlebar">
                      <h1>100+</h1>
                      <p>Cancel Order</p>
                    </div>
                    <div className="ap-po-details__icon-area">
                      <div className="svg-icon order-bg-opacity-danger color-danger">
                        <i className="uil uil-ban" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xxl-3 col-sm-6 mb-5">
            <div className="ap-po-details ap-po-details--2 p-25 radius-xl d-flex justify-content-between">
              <Link to='/order'>
                <div className="overview-content w-100">
                  <div className=" ap-po-details-content d-flex flex-wrap justify-content-between">
                    <div className="ap-po-details__titlebar">
                      <h1>100+</h1>
                      <p>Refund orders</p>
                    </div>
                    <div className="ap-po-details__icon-area">
                      <div className="svg-icon order-bg-opacity-info color-info">
                        <i className="uil uil-money-withdraw" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xxl-3 col-sm-6 mb-5">
            <div className="ap-po-details ap-po-details--2 p-25 radius-xl d-flex justify-content-between">
              <Link to='/order'>
                <div className="overview-content w-100">
                  <div className=" ap-po-details-content d-flex flex-wrap justify-content-between">
                    <div className="ap-po-details__titlebar">
                      <h1>100+</h1>
                      <p>Dispatch Order</p>
                    </div>
                    <div className="ap-po-details__icon-area">
                      <div className="svg-icon order-bg-opacity-dark color-dark">
                        <i className="uil uil-truck-loading" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xxl-3 col-sm-6 mb-5">
            <div className="ap-po-details ap-po-details--2 p-25 radius-xl d-flex justify-content-between">
              <Link to='/order'>
                <div className="overview-content w-100">
                  <div className=" ap-po-details-content d-flex flex-wrap justify-content-between">
                    <div className="ap-po-details__titlebar">
                      <h1>100+</h1>
                      <p>Completed Order</p>
                    </div>
                    <div className="ap-po-details__icon-area">
                      <div className="svg-icon order-bg-opacity-success color-success">
                        <i className="uil uil-check-circle" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home