import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import axios from 'axios'
import ekologo from '../../assets/img/default-img.png'
const OrderView = () => {
    const { id } = useParams();
    const [pro, setPro] = useState([])
    const [values, setValues] = useState({})
    const [catList, setCatList] = useState([]);
    const [loading, setloading] = useState(false)
    const [addressData, setAddressData] = useState({})
    const [userData, setUserData] = useState({})
    // product view api
    const fetchSingleProduct = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/order-view/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            console.log("res", res.data.data.products)
            console.log("setValues", res.data.data)
            setValues(res.data.data)
            setPro(res.data.data.products)
            setAddressData(res.data.data.address)
            setUserData(res.data.data.user_id)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("single product error", error)
        }
    }
    console.log(userData)
    useEffect(() => {
        fetchSingleProduct();
    }, [])
    // console.log("values",values)
    const calculateTaxes = (products) => {
        return products.reduce((acc, order) => {
            const cgstRate = order?.product_id?.category_id?.C_GST || 0;
            const sgstRate = order?.product_id?.category_id?.S_GST || 0;

            const productTotal = order.totalPrice;
            const cgstAmount = (productTotal * cgstRate) / 100;
            const sgstAmount = (productTotal * sgstRate) / 100;

            return {
                cgst: acc.cgst + cgstAmount,
                sgst: acc.sgst + sgstAmount
            };
        }, { cgst: 0, sgst: 0 });
    };
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    <li className="breadcrumb-item ">
                                        <Link to="/order">
                                            <i className="fa-solid fa-circle-arrow-left"></i>
                                            <span>Back</span>
                                        </Link>
                                    </li>
                                    / Order Details
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Order Details
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className='card'>
                            <div className='card-header'>
                                <h4>Order Details</h4>
                            </div>
                            <div className='card-body'>
                                <div className="order-card ">
                                    <div className="order-card-head">
                                        <p className="mb-0">
                                            <span className="badges">Order Id: {values.order_id}</span> Order Placed: {new Date(values?.order_date).toLocaleDateString()} {new Date(values?.order_date).toLocaleTimeString()}
                                        </p>
                                        <p className="mb-0">
                                            Payment Mode: <span className="text-success">{values?.payment_method}</span>
                                        </p>
                                    </div>
                                    {
                                        pro.map((order, index) => {
                                          
                                            return (
                                                <div key={order._id} className="order-card-mddle">
                                                    <div className="order-card-bx">
                                                        <img src={order?.product_id?.product_images?.length > 0 && order?.product_id?.product_images?.[0]?.image
                                                            ? `${imgUrl}/${order?.product_id?.product_images?.[0]?.image}`
                                                            : ekologo
                                                        }
                                                            alt="" />
                                                        <div className="order-card-bx-content">
                                                            <h6>{order.name} ({order.grams})</h6>
                                                            <span>Price : INR {order.price}</span><br />
                                                            <span>Category : {order?.product_id?.category_id?.name}</span>
                                                            <p> QTY.: {order.quantity}</p>
                                                            {/* <p className="price">
                                                                Total Price : INR {order.totalPrice}
                                                            </p> */}
                                                        </div>
                                                    </div>
                                                    {/* <p className="mb-0 text-success fw-600">
                                                    <em className="fas fa-circle me-1" />
                                                   {values.status}
                                                </p> */}
                                                    <p className="mb-0">
                                                        Delivery Expected By:{" "}
                                                        <span className="d-block text-dark">24 Jun 2024</span>
                                                    </p>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="order-card-bttm">
                                        <p className="mb-0">
                                            Order Placed: {new Date(values?.order_date).toLocaleDateString()} {new Date(values?.order_date).toLocaleTimeString()}
                                            {/* | Shipped on Jan 21, 2020 | 10:30 AM */}
                                        </p>
                                        <div>
                                            <a className='status-text me-3'>
                                                <p className={`mb-0 ${values?.status == "Cancelled" ? "text-danger" : "text-success"} fw-600`}>
                                                    <em className="fas fa-circle me-1" />
                                                    {values.status}
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-lg-6">
                                        <div className="checkout-add-innr">
                                            <div className="select-add">
                                                <h5 className="fz-20 fw-600">user details</h5>
                                            </div>
                                            <div className="p-3">
                                                <p> Name : {userData?.full_name}</p>
                                                <p> Email : {userData?.email}</p>
                                                <p> Mobile : {userData?.mobile}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="checkout-add-innr">
                                            <div className="select-add">
                                                <h5 className="fz-20 fw-600">Address</h5>
                                            </div>
                                            <div className="p-3">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <h5 className="fz-16 fw-500">Billing Details</h5>
                                                        <p>
                                                            {addressData?.billingAddress?.firstName}{addressData?.billingAddress?.lastName}, {addressData?.billingAddress?.fullAddress}, {addressData?.billingAddress?.landmark}
                                                            {addressData?.billingAddress?.city}, {addressData?.billingAddress?.state}({addressData?.billingAddress?.pinCode}), {addressData?.billingAddress?.country}{" "}
                                                            <a href="javascript:void(0);" className="clr d-block">
                                                                {addressData?.billingAddress?.country_code}-{addressData?.billingAddress?.mobileNumber}
                                                            </a>
                                                        </p>
                                                    </div>
                                                    {
                                                        addressData?.Ship_different_address && (
                                                            <div className="col-lg-6">
                                                                <h5 className="fz-16 fw-500">Shipping Address</h5>
                                                                <p>
                                                                    {addressData?.shippingAddress?.firstName}{addressData?.shippingAddress?.lastName}, {addressData?.shippingAddress?.fullAddress}, {addressData?.shippingAddress?.landmark}
                                                                    {addressData?.shippingAddress?.city}, {addressData?.shippingAddress?.state}({addressData?.shippingAddress?.pinCode}), {addressData?.shippingAddress?.country}{" "}
                                                                    <a href="javascript:void(0);" className="clr d-block">
                                                                        {addressData?.shippingAddress?.country_code}-{addressData?.shippingAddress?.mobileNumber}
                                                                    </a>
                                                                </p>
                                                                <a href="javascript:void(0);" className="txt-org">
                                                                    Edit
                                                                </a>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="bill-summary-main">
                                            <h5>Bill Summary</h5>
                                            <ul className="bill-summary">
                                                <li>
                                                    <span>Subtotal</span>
                                                    <span>INR {values?.total_amount}</span>
                                                </li>
                                                {
                                                    values?.coupon_code && (
                                                        <li>
                                                            <span>Coupon({values?.coupon_code})</span>
                                                            <span>-(INR {values?.couponDiscountAmount})</span>
                                                        </li>
                                                    )
                                                }
                                                {/* <li>
                                                    <span>CGST</span>
                                                    <span>INR {calculateTaxes(pro).cgst.toFixed(2)}</span>
                                                </li>
                                                <li>
                                                    <span>SGST</span>
                                                    <span>INR {calculateTaxes(pro).sgst.toFixed(2)}</span>
                                                </li> */}
                                                <li>
                                                    <span>Total GST</span>
                                                    <span>INR {(values?.totalOrderGSTAmount)?.toFixed(2)}</span>
                                                </li>
                                                <li>
                                                    <span>Shipping</span>
                                                    <span>INR 0</span>
                                                </li>
                                                <li>
                                                    <span>Total</span>
                                                    <span className="txt-org">INR {(values?.totalOrderAmountWithGSTAndCoupon)?.toFixed(2)}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {values?.status === "Cancelled" &&
                                        <div className="col-lg-12">
                                            <div className="checkout-add-innr">
                                                <div className='p-3 text-center'>
                                                    <h6 className='text-danger'>Order Cancelled</h6>
                                                    <p className='mb-0'>{values?.order_cancel_reason}</p>
                                                    <p className='mb-0'>{values?.order_cancel_detail}</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className='text-end d-flex justify-content-end mt-2'>
                                        <Link className='btn btn-primary' to={`/invoice/${values?._id}`}>Download Invoice</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default OrderView







