import React, { useState, useEffect } from 'react'
// import logo from '../../src/assets/images/logo.png'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { imgUrl } from '../Helper/BaseUrl';
import cancel from '../../assets/img/cancel-img.png'
import paid from '../../assets/img/paid-img.png'

function Invoive() {
    let { id } = useParams()

    const [orderList, setOrderList] = useState([])
    const [invoiceData, setinvoiceData] = useState({})
    const [addressData, setAddressData] = useState({})
    const [addressData1, setAddressData1] = useState({})
    const [ship, setShip] = useState({})
    const [webData, setWebData] = useState({})

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchSingleCms();
    }, [])

    // // fetch sub category
    const fetchSingleCms = async () => {
        try {
            const res = await axios.get(`${imgUrl}/user/invoice-pdf/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("invoiceData", res.data.data)
            // console.log("webData", res?.data?.WebDeails)
            // console.log("addressData", res.data)
            setinvoiceData(res.data.data)
            setWebData(res?.data?.WebDeails)
            setAddressData(res?.data?.data?.address?.billingAddress)
            setShip(res?.data?.data?.address?.Ship_different_address)
            setAddressData1(res?.data?.data?.address?.shippingAddress)
            setOrderList(res?.data?.data?.products)
        } catch (error) {
            console.log("fetch cms error", error)
        }
    }
    const calculateTotal = () => {
        return orderList.reduce((total, item) => {
            const basePrice = item?.price * item.quantity;
            const gstPercentage = item.product_id?.category_id?.C_GST || 0;
            const sgstPercentage = item.product_id?.category_id?.S_GST || 0;
            // const discount = item?.product_id?.discount_price || 0;
            const gstAmount = (basePrice * (gstPercentage + sgstPercentage)) / 100;
            return total + basePrice + gstAmount
            // const discountedAmount = discount > 0
            //     ? (basePrice + gstAmount) * (1 - discount / 100)
            //     : basePrice + gstAmount;

            // return total + discountedAmount;
        }, 0).toFixed(2);;
    }
    const formatTime = (dateString) => {
        const date = new Date(dateString);
        let hours = date.getUTCHours();
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        const seconds = date.getUTCSeconds().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format
        return `${hours}:${minutes}:${seconds} ${ampm}`;
        // return date.toISOString().split('T')[1].split('.')[0]; // Extract time portion
    };

    const calculateFinalPrice = () => {
        const subtotal = parseFloat(calculateTotal());
        if (invoiceData?.couponDetails?.discount > 0) {
            const discountAmount = (subtotal * invoiceData?.couponDetails?.discount) / 100;
            return (subtotal - discountAmount).toFixed(2);
        }
        return subtotal.toFixed(2);
    };
    return (
        <>
            <div className="invoice-main" style={{
                backgroundImage: `url(${(invoiceData?.status === 'Cancelled' ||
                    invoiceData?.status === 'Cancelled REFUND PENDING' ||
                    invoiceData?.status === 'Cancelled REFUND PROCESSED' ||
                    invoiceData?.status === 'Cancelled REFUND COMPLETE') ? cancel : paid})`
            }}>
                <table style={{ width: "100%" }}>
                    <tbody>
                        <tr>
                            <td>
                                <h5
                                    style={{
                                        fontSize: 26,
                                        fontWeight: "bolder",
                                        color: "#000",
                                        textAlign: "center"
                                    }}
                                >
                                    TAX INVOICE
                                </h5>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table style={{ width: "100%", border: "1px solid #000" }}>
                    <tbody>
                        <tr>
                            <td style={{ width: "50%", border: "1px solid #000", padding: 5 }}>
                                <img src="{logo}" alt="" style={{ width: 90 }} />
                                <div>
                                    <h4 style={{ fontSize: 20, fontWeight: "bolder", color: "#000" }}>
                                        Sweetz N'snackz
                                    </h4>
                                    <p>
                                        <span>    {webData?.address}, {webData?.contact} ,</span><br />
                                        {webData?.email}
                                    </p>
                                </div>
                            </td>
                            <td
                                style={{
                                    width: "50%",
                                    border: "1px solid #000",
                                    textAlign: "center"
                                }}
                            >
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "50%" }}>
                                                <p>
                                                    {" "}
                                                    <b>Invoice No.</b> <br /> SW/001{" "}
                                                </p>
                                            </td>
                                            <td style={{ width: "50%" }}>
                                                <p>
                                                    {" "}
                                                    <b>Invoice Date</b> <br />{invoiceData?.order_date?.split("T")[0]}
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table
                    style={{
                        width: "100%",
                        border: "1px solid #000",
                        borderTop: 0,
                        borderBottom: 0
                    }}
                >
                    <tbody>
                        <tr>
                            <td style={{ width: "79%", padding: 10 }}>
                                <p>
                                    {" "}
                                    <strong>{addressData?.firstName}{" "}{addressData?.lastName} </strong>{" "}
                                </p>
                                <p>Biiling Address:{" "} <span>{addressData?.landmark} ,{addressData?.floor} ,{addressData?.fullAddress},</span><br />
                                    {addressData?.state},({addressData?.pinCode}), {addressData?.city} </p>
                                <p>Mobile: {addressData?.mobileNumber}</p>
                                <p>EMAIL:- {addressData?.email}</p>
                                {/* {
                                    ship &&
                                    <> */}
                                <p>SHIP TO</p>
                                <p>
                                    {" "}
                                    <strong>{addressData1?.firstName}{" "}{addressData1?.lastName}</strong>{" "}
                                </p>
                                <p>Shipping Address:{" "} <span>{addressData1?.landmark} ,{addressData1?.floor} ,{addressData1?.fullAddress},</span><br />
                                    {addressData1?.state},({addressData1?.pinCode}), {addressData1?.city} </p>

                                <p style={{ marginBottom: 0 }}>Mobile: {addressData1?.mobileNumber}</p>
                                <p style={{ marginBottom: 0 }}>EMAIL:- {addressData1?.email}</p>
                                {/* </>
                                } */}
                            </td>
                            <td
                                style={{
                                    width: "21%",
                                    border: "1px solid #000",
                                    padding: 5,
                                    borderBottom: 0,
                                    borderTop: 0
                                }}
                            >
                                <p style={{ marginBottom: 0, fontSize: 15 }}>Location:- {addressData?.city}</p>
                                <p style={{ marginBottom: 0, fontSize: 15 }}>Order ID:- {invoiceData?.order_id}</p>
                                <p style={{ marginBottom: 0, fontSize: 15 }}>Order Date:- {invoiceData?.order_date?.split("T")[0]}</p>
                                <p style={{ marginBottom: 0, fontSize: 15 }}>
                                    {" "}
                                    Order time:-
                                    {/* {formattedTime} */}
                                    {new Date(invoiceData?.order_date).toLocaleTimeString()}
                                    {/* {formatTime(invoiceData?.order_date)} */}
                                    {/* {(invoiceData?.order_date).toLocaleTimeString('en-US', { timeZone: 'UTC' })} */}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table style={{ width: "100%" }}>
                    <thead style={{ backgroundColor: "#CFE9F9", color: "#000" }}>
                        <tr>
                            <td
                                style={{
                                    border: "1px solid #000",
                                    width: "10%",
                                    padding: 5,
                                    fontSize: 12
                                }}
                            >
                                S.NO.
                            </td>
                            <td
                                style={{
                                    border: "1px solid #000",
                                    width: "10%",
                                    padding: 5,
                                    fontSize: 12
                                }}
                            >
                                UPC
                            </td>
                            <td
                                style={{
                                    border: "1px solid #000",
                                    width: "30%",
                                    padding: 5,
                                    fontSize: 12
                                }}
                            >
                                Product Name
                            </td>
                            <td
                                style={{
                                    border: "1px solid #000",
                                    width: "10%",
                                    padding: 5,
                                    fontSize: 12
                                }}
                            >
                                Price
                            </td>
                            <td
                                style={{
                                    border: "1px solid #000",
                                    width: "8%",
                                    padding: 5,
                                    fontSize: 12
                                }}
                            >
                                QTY
                            </td>
                            <td
                                style={{
                                    border: "1px solid #000",
                                    width: "15%",
                                    padding: 5,
                                    fontSize: 12
                                }}
                            >
                                Discount %
                            </td>

                            <td
                                style={{
                                    border: "1px solid #000",
                                    width: "10%",
                                    padding: 5,
                                    fontSize: 12
                                }}
                            >
                                CGST %
                            </td>
                            <td
                                style={{
                                    border: "1px solid #000",
                                    width: "10%",
                                    padding: 5,
                                    fontSize: 12
                                }}
                            >
                                SGST %
                            </td>
                            <td
                                style={{
                                    border: "1px solid #000",
                                    width: "10%",
                                    padding: 5,
                                    fontSize: 12
                                }}
                            >
                                Taxable Value %
                            </td>
                            <td
                                style={{
                                    border: "1px solid #000",
                                    width: "10%",
                                    padding: 5,
                                    fontSize: 12
                                }}
                            >
                                Total
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orderList.map((order, index) => {
                                console.log(order.couponCodeDiscount)
                                console.log(order.shipping)
                                return (
                                    <tr key={order?._id} >
                                        <td
                                            style={{
                                                border: "1px solid #000",
                                                borderBottomColor: "transparent",
                                                padding: 5,
                                                fontSize: 12
                                            }}
                                        >
                                            {index + 1}
                                        </td>
                                        <td
                                            style={{
                                                border: "1px solid #000",
                                                borderBottomColor: "transparent",
                                                padding: 5,
                                                fontSize: 12
                                            }}
                                        />
                                        <td
                                            style={{
                                                border: "1px solid #000",
                                                borderBottomColor: "transparent",
                                                padding: 5,
                                                fontSize: 12
                                            }}
                                        >
                                            {order.name} ({order.grams})
                                        </td>
                                        <td
                                            style={{
                                                border: "1px solid #000",
                                                borderBottomColor: "transparent",
                                                padding: 5,
                                                fontSize: 12
                                            }}
                                        >
                                            {order.price} {" "}
                                            {order.grams === "150g" ?
                                                (<del>{order?.product_id?.weight_price?.[0]?.prices?.India}</del>) : (
                                                    <del>{order?.product_id?.weight_price?.[1]?.prices?.India}</del>
                                                )}
                                        </td>
                                        <td
                                            style={{
                                                border: "1px solid #000",
                                                borderBottomColor: "transparent",
                                                padding: 5,
                                                fontSize: 12
                                            }}
                                        >
                                            {order.quantity}
                                        </td>
                                        <td
                                            style={{
                                                border: "1px solid #000",
                                                borderBottomColor: "transparent",
                                                padding: 5,
                                                fontSize: 12
                                            }}
                                        >
                                            {order?.product_id?.discount_price}
                                        </td>

                                        <td
                                            style={{
                                                border: "1px solid #000",
                                                borderBottomColor: "transparent",
                                                padding: 5,
                                                fontSize: 12
                                            }}>
                                            {order?.C_GST}
                                        </td>
                                        <td
                                            style={{
                                                border: "1px solid #000",
                                                borderBottomColor: "transparent",
                                                padding: 5,
                                                fontSize: 12
                                            }}>
                                            {order?.S_GST}
                                        </td>
                                        <td
                                            style={{
                                                border: "1px solid #000",
                                                borderBottomColor: "transparent",
                                                padding: 5,
                                                fontSize: 12
                                            }}
                                        >
                                            {order?.totalGST}
                                        </td>
                                        <td
                                            style={{
                                                border: "1px solid #000",
                                                borderBottomColor: "transparent",
                                                padding: 5,
                                                fontSize: 12
                                            }}>
                                            {/* {(calculateTotal() * invoiceData?.couponDetails?.discount / 100).toFixed(2)} */}
                                            {/* INR {(order.totalCoupnDiscountAmount) + (order.totalGSTAmount)} */}
                                            INR {order.totalPrice}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        <tr>
                            <td style={{ border: "1px solid #000" }} />
                            <td style={{ border: "1px solid #000" }} />
                            <td style={{ border: "1px solid #000" }} />
                            <td style={{ border: "1px solid #000" }} />
                            <td style={{ border: "1px solid #000" }} />
                            <td style={{ border: "1px solid #000" }} />
                            <td style={{ border: "1px solid #000" }} />
                            <td style={{ border: "1px solid #000" }} />
                            <td style={{ border: "1px solid #000" }} />
                            <td style={{ border: "1px solid #000" }} />
                        </tr>
                    </tbody>

                    <tfoot style={{ backgroundColor: "transparent", color: "#000" }}>
                        <tr>
                            <td style={{ border: "1px solid #000", padding: 5 }} />
                            <td style={{ border: "1px solid #000", padding: 5 }} />
                            <td
                                style={{
                                    border: "1px solid #000",
                                    padding: 5,
                                    fontSize: 12,
                                    textAlign: "right"
                                }}
                            >
                                <b>RECEIVED AMOUNT</b>
                            </td>
                            <td style={{ border: "1px solid #000", padding: 5 }} />
                            <td style={{ border: "1px solid #000", padding: 5, fontSize: "10px" }} >
                                <strong>Total Amount:</strong> INR{" "}{invoiceData?.totalOrderAmount || 0}
                            </td>
                            <td style={{ border: "1px solid #000", padding: 5 }} />
                            <td style={{ border: "1px solid #000", padding: 5, fontSize: 12 }}>
                                <strong>Coupon Discount:</strong> INR{" "}(-{invoiceData?.couponDiscountAmount || 0})
                            </td>
                            <td style={{ border: "1px solid #000", padding: 5, fontSize: 12 }}>
                                <strong>Total GST:</strong> INR{" "}(+{invoiceData?.totalOrderGSTAmount || 0})
                            </td>
                            <td style={{ border: "1px solid #000", padding: 5, fontSize: 12 }}>
                                <strong> Shipping:</strong> INR (+0)
                            </td>
                            <td style={{ border: "1px solid #000", padding: 5, fontSize: 12 }}>
                                <strong>
                                    INR {invoiceData.totalOrderAmountWithGSTAndCoupon}
                                </strong>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <table
                    style={{
                        width: "100%",
                        border: "1px solid #000",
                        marginTop: 15,
                        borderBottom: "none"
                    }}
                >
                    <tbody>
                        <tr>
                            {/* <td style={{ padding: 10 }}>
                                <p>
                                    Total Amount (in words) <br />
                                    <strong>Two Hundred Rupees only</strong>
                                </p>
                            </td> */}
                        </tr>
                    </tbody>
                </table>
                <table style={{ width: "100%", border: "1px solid #000" }}>
                    <tbody>
                        <tr>
                            <td style={{ width: "33%", padding: 10, border: "1px solid #000" }}>
                                <h4 style={{ fontSize: 18 }}>Bank Details</h4>
                                <p style={{ marginBottom: 0 }}>
                                    Name: <span> Sweetz N'snackz</span>
                                </p>
                                <p style={{ marginBottom: 0 }}>
                                    IFSC Code: <span>ICIC05636748</span>
                                </p>
                                <p style={{ marginBottom: 0 }}>
                                    Account No: <span>135890004532</span>
                                </p>
                                <p style={{ marginBottom: 0 }}>
                                    Bank: <span>ICICI Bank ,JLN Marg Jaipur </span>
                                </p>
                            </td>
                            <td style={{ width: "33%", border: "1px solid #000", padding: 10 }}>
                                <p>Terms and Conditions</p>
                                <p style={{ marginBottom: 0 }}>
                                    <strong>
                                        1. All disputes are subject to Jaipur jurisdiction only{" "}
                                    </strong>
                                </p>
                            </td>
                            <td
                                style={{ width: "33%", padding: 10, borderBottom: "1px solid #000" }}
                            >
                                <p style={{ marginBottom: 40 }}>For </p>
                                <p>Sweetz N'snackz (Authorised Signatory ) </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table style={{ width: "100%", border: "1px solid #000", borderTop: 0 }}>
                    <tbody>
                        <tr>
                            <td style={{ padding: 8 }}>
                                <h4 style={{ fontSize: 18 }}>Terms &amp; Conditions</h4>
                                <p style={{ marginBottom: 0 }}>
                                    1. If you have any issues or queries in respect of your order,
                                    please contact customer chat support through the sweetznsnackz
                                    platform or drop in email at info@sweetznsnack.com.
                                </p>
                                <p style={{ marginBottom: 0 }}>
                                    2. In case you need to get more information about the seller's FSSAI
                                    status, please visit https://foscos.fssai.gov.in/ and use the FBO
                                    search option with the FSSAI License / Registration number.
                                </p>
                                <p style={{ marginBottom: 0 }}>
                                    3. Please note that we never ask for bank account details such as
                                    CVV, account number, UPI Pin, etc. across our support channels. For
                                    your safety please do not share these details with anyone over any
                                    medium.
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Invoive