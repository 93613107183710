import React, { useState } from 'react';

const TruncatedText = ({ text, limit}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const words = text?.split(' ');
    const truncated = words?.slice(0, limit)?.join(' ');
    const isLongText = words?.length > limit;

    return (
        <>
            {isExpanded ? text : truncated}
            {isLongText && (
                <span
                    onClick={() => setIsExpanded(!isExpanded)}
                    className="text-secondary p-0 ms-1"
                >
                    {isExpanded ? ' Read Less' : '... Read More'}
                </span>
            )}
        </>
    );
};

export default TruncatedText;