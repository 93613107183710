import React, { useEffect, useState } from 'react'
import searchImg from "../../assets/img/svg/search.svg"
import axios from 'axios'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import Pagination from '../Helper/Pagination'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import ekologo from '../../assets/img/default-img.png'
import TruncatedText from '../Helper/TruncatedText'
const ProductReviews = () => {
    const [reviewList, setReviewList] = useState([]);
    const [loading, setloading] = useState(false)
    useEffect(() => {
        fetchReviews();
    }, [])

    // review api
    const fetchReviews = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${imgUrl}user/review-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            console.log(res)
            setReviewList(res.data.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("fetch review error", error)
        }
    }

    //  handle status change
    const handleStatusToggle = async (listId, currentStatus) => {
        try {
            setloading(true)
            const newStatus = !currentStatus;
            const response = await axios.get(
                `${imgUrl}/user/review-status-change/${listId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );
            // console.log(response)
            if (response.data.status) {
                toast.success(response.data.message)
                setloading(false)
                setReviewList(prevState =>
                    prevState.map(list =>
                        list._id === listId ? { ...list, status: newStatus } : list
                    )
                );
            }
        } catch (error) {
            setloading(false)
            console.error('Error changing user status:', error);
        }
    };
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>)}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main">
                            <h4 className="text-capitalize breadcrumb-title">Product Reviews list</h4>
                            <div className="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"><i className="uil uil-estate"></i>Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Product Reviews list</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className='card-header'>
                                <div>
                                    <h6>Total Review ({reviewList?.length})</h6>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                                    <div className="table-responsive">
                                        <table className="table mb-0 table-borderless table-striped">
                                            <thead>
                                                <tr className="userDatatable-header">
                                                    <th>
                                                        <span className="userDatatable-title">
                                                            S. No.
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">
                                                            Review Image
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Product Name</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Title</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Raing</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title" >Description</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title" >Status</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title float-end">
                                                            action
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    reviewList && reviewList.map((list, index) => {
                                                        return (
                                                            <tr key={list._id}>
                                                                <td>
                                                                    <div className="userDatatable-content">
                                                                        {index + 1}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-inline-title">
                                                                        <img
                                                                            src={list?.review_images?.[0]?.image ? `${imgUrl}/${list.review_images[0].image}` : ekologo}
                                                                            // src={
                                                                            //     list?.review_images?.length > 0 && list?.review_images?.[0]?.image
                                                                            //         ? `${imgUrl}/${list.review_images[0].image}` || ekologo
                                                                            //         : ekologo
                                                                            // }
                                                                            className="rounded-circle wh-50" alt="Review Image"

                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <Link to={`/productview/${list?.prduct_id?._id}`} className="view">
                                                                        <div className="userDatatable-content word-wrap">

                                                                            <TruncatedText
                                                                                text={list?.prduct_id?.product_name}
                                                                                limit={3}
                                                                            />
                                                                        </div>
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content">
                                                                        {list.title}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content">{list.rating}</div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content wrap-code">
                                                                        <TruncatedText
                                                                            text={list.description}
                                                                            limit={5}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content d-inline-block">
                                                                        <div className="form-check form-switch">
                                                                            <input className="form-check-input" checked={list?.status} onChange={() => handleStatusToggle(list._id, list.status)} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                                        <li>
                                                                            <Link to={`/reviewdetails/${list._id}`} className="view">
                                                                                <i className="uil uil-eye" />
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <Pagination
                                        currentPage={page}
                                        onPageChange={handlePageChange}
                                        itemPerPage={itemPerPage}
                                        totalItems={totalItems}
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductReviews

