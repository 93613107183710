import React, { useEffect, useState } from 'react'
import upload from "../../assets/img/svg/upload.svg"
import trash from "../../assets/img/svg/trash-2.svg"
import media from "../../assets/img/food-restaurant-media.png"
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Link, useNavigate, useParams } from 'react-router-dom'

const CorporateImageView = () => {
    const { id } = useParams();
    const [values, setValues] = useState({
        name: "",
        description: "",
        start_price: "",
        end_price: "",
        gift_type: "",
    })
    const [imgFile, setImgFile] = useState(null)
    const [bannerImgFile, setBannerImgFile] = useState(null)
    const navigate = useNavigate();

    const fetchGifts = async () => {
        try {
            const res = await axios.get(`${baseUrl}/gifting-view/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log(res.data)
            setValues(res.data.data)
            setBannerImgFile(res.data.data.banner_image)
            setImgFile(res.data.data.large_image)
        } catch (error) {
            console.log("error fetch gifts", error)
        }
    }

    useEffect(() => {
        fetchGifts();
    }, [])
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    <li className="breadcrumb-item ">
                                        <Link to="/corporateimagelist">
                                            <i className="fa-solid fa-circle-arrow-left"></i>
                                            <span>Back</span>
                                        </Link>
                                    </li>
                                   / Corporate Images details
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Corporate Images details
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="add-product__body px-sm-40 px-20">
                                    <form>
                                        <div className='form-basic'>
                                            <div className='row'>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='name'
                                                            value={values?.name}
                                                            disabled
                                                            placeholder="enter name"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">description</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='description'
                                                            value={values?.description}
                                                            disabled
                                                            placeholder="description"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name8">Start Price</label>
                                                        <input
                                                            type="text"
                                                            name='start_price'
                                                            value={values?.start_price}
                                                            disabled
                                                            className="form-control"
                                                            id="name8"
                                                            placeholder="enter starting Price"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name8">End Price</label>
                                                        <input
                                                            type="number"
                                                            name='end_price'
                                                            value={values?.end_price}
                                                            disabled
                                                            className="form-control"
                                                            id="name8"
                                                            placeholder="enter ending price"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name8">gift type</label>
                                                        <input
                                                            type="number"
                                                            name='gift_type'
                                                            value={values?.gift_type}
                                                            disabled
                                                            className="form-control"
                                                            id="name8"
                                                            placeholder="enter gift type"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 mb-3">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-500">Corporate banner image</h6>
                                                        <div className="upload-product-media d-flex justify-content-between align-items-center mt-25">
                                                            <div className="upload-media-area">
                                                                {
                                                                    imgFile && (
                                                                        <img
                                                                            src={`${imgUrl}/${imgFile}`}
                                                                            alt="img"
                                                                        />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 mb-3">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-500">Corporate Large image</h6>
                                                        <div className="upload-product-media d-flex justify-content-between align-items-center mt-25">
                                                            <div className="upload-media-area">
                                                                {
                                                                    bannerImgFile && (
                                                                        <img
                                                                            src={`${imgUrl}/${bannerImgFile}`}
                                                                            alt="img"
                                                                        />
                                                                    ) 
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CorporateImageView