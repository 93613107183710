import React, { useState } from 'react'
import upload from "../../assets/img/svg/upload.svg"
import media from '../../assets/img/default-img.png'
import trash from "../../assets/img/svg/trash-2.svg"
// import media from "../../assets/img/food-restaurant-media.png"
import baseUrl from '../Helper/BaseUrl'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'

const CorporateImageAdd = () => {
    const [values, setValues] = useState({
        name: "",
        description: "",
        start_price: "",
        end_price: "",
        gift_type: "",
    })
    const [imgFile, setImgFile] = useState(null)
    const [bannerImgFile, setBannerImgFile] = useState(null)
    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }
    const handleImageRemove = () => {
        setImgFile(null);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const toastId = 'category-Id'
        if (!values.name || !values.description || !values.start_price || !values.end_price || !values.gift_type) {
            if (!toast.isActive(toastId)) {
                toast.error("All fields are required!", { toastId: toastId });
            }
            return;
        }
        if (!imgFile) {
            if (!toast.isActive(toastId)) {
                toast.error("Please upload an image.", { toastId: toastId });
            }
            return;
        }
        if (!bannerImgFile) {
            if (!toast.isActive(toastId)) {
                toast.error("Please upload banner image.", { toastId: toastId });
            }
            return;
        }
        try {
            const formData = new FormData();
            formData.append('name', values.name)
            formData.append('description', values.description)
            formData.append('start_price', values.start_price)
            formData.append('end_price', values.end_price)
            formData.append('gift_type', values.gift_type)
            if (bannerImgFile) {
                formData.append('banner_image', bannerImgFile)
            }
            if (imgFile) {
                formData.append('large_image', imgFile)
            }
            const res = await axios.post(`${baseUrl}/gifting-store`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("****",res);
            if (res.data.status) {
                toast.success(res.data.message)
                navigate('/corporateimagelist')
            } else {
                toast.error(res.data.message)
            }

        } catch (error) {
            console.log("category post error", error)
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    add Corporate Images
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                add Corporate Images
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="add-product__body px-sm-40 px-20">
                                    <form onSubmit={handleSubmit}>
                                        <div className='form-basic'>
                                            <div className='row'>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='name'
                                                            value={values?.name}
                                                            onChange={handleChange}
                                                            placeholder="enter  name"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">description</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='description'
                                                            value={values?.description}
                                                            onChange={handleChange}
                                                            placeholder="description"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name8">Start Price</label>
                                                        <input
                                                            type="text"
                                                            name='start_price'
                                                            value={values?.start_price}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            id="name8"
                                                            placeholder="enter starting Price"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name8">End Price</label>
                                                        <input
                                                            type="number"
                                                            name='end_price'
                                                            value={values?.end_price}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            id="name8"
                                                            placeholder="enter ending price"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name8">gift type</label>
                                                        <input
                                                            type="text"
                                                            name='gift_type'
                                                            value={values?.gift_type}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            id="name8"
                                                            placeholder="enter gift type"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 mb-3">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-500">Corporate banner image((size in pixels) ( 316.5*316.5))</h6>
                                                        <label
                                                            htmlFor="upload"
                                                            className="file-upload__label"
                                                        >
                                                            <span className="upload-product-img px-10 d-block">
                                                                <span className="file-upload">
                                                                    <img
                                                                        className="svg"
                                                                        src={upload}
                                                                        alt=""
                                                                    />
                                                                    <input
                                                                        id="upload"
                                                                        className="file-upload__input"
                                                                        type="file"
                                                                        name="file-upload"
                                                                        onChange={(e) => setImgFile(e.target.files[0])}
                                                                    />
                                                                </span>
                                                            </span>
                                                        </label>
                                                        <div className="upload-product-media d-flex justify-content-between align-items-center mt-25">
                                                            <div className="upload-media-area">
                                                                {
                                                                    imgFile ? (
                                                                        <img
                                                                            src={URL.createObjectURL(imgFile)}
                                                                            alt="img"
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={media}
                                                                            alt="img"
                                                                        />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 mb-3">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-500">Corporate Large image ((size in pixels) ( 316.5*316.5))</h6>
                                                        <label
                                                            htmlFor="upload1"
                                                            className="file-upload__label"
                                                        >
                                                            <span className="upload-product-img px-10 d-block">
                                                                <span className="file-upload">
                                                                    <img
                                                                        className="svg"
                                                                        src={upload}
                                                                        alt=""
                                                                    />
                                                                    <input
                                                                        id="upload1"
                                                                        className="file-upload__input"
                                                                        type="file"
                                                                        name="file-upload"
                                                                        onChange={(e) => setBannerImgFile(e.target.files[0])}
                                                                    />
                                                                </span>
                                                            </span>
                                                        </label>
                                                        <div className="upload-product-media d-flex justify-content-between align-items-center mt-25">
                                                            <div className="upload-media-area">
                                                                {
                                                                    bannerImgFile ? (
                                                                        <img
                                                                            src={URL.createObjectURL(bannerImgFile)}
                                                                            alt="img"
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={media}
                                                                            alt="img"
                                                                        />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="button-group mt-1 add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                    <button type='submit' className="btn btn-primary btn-default btn-squared text-capitalize">
                                                        save Gift's
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CorporateImageAdd