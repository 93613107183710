import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import media from "../../assets/img/food-restaurant-media.png"
import upload from "../../assets/img/svg/upload.svg"

const BottomBanner = () => {
    const [loading, setloading] = useState(false)
    const [imgFile, setImgFile] = useState(null)
    const [bannerImgFile, setBannerImgFile] = useState(null)
    const [bannerImgFile1, setBannerImgFile1] = useState(null)
    const [imgPreview, setImgPreview] = useState(null)
    const [bannerPreview, setBannerPreview] = useState(null)
    const [banner1Preview, setBanner1Preview] = useState(null)

    const fetchBannerList = async () => {
        try {
            const res = await axios.get(`${imgUrl}/user/banner2-view`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            setImgFile(res.data.data.banner_1)
            setBannerImgFile(res.data.data.banner_2)
            setBannerImgFile1(res.data.data.banner_3)
            // Set initial previews from API URLs
            setImgPreview(res.data.data.banner_1)
            setBannerPreview(res.data.data.banner_2)
            setBanner1Preview(res.data.data.banner_3)

        } catch (error) {
            console.log("error fetch gift", error)
        }
    }
    // https://sweetznsnackzapi.objectsquare.in/user/banner2-update
    const handleSubmit = async (e) => {
        e.preventDefault()
        setloading(true)

        const formData = new FormData()
        formData.append('banner_1', imgFile)
        formData.append('banner_2', bannerImgFile)
        formData.append('banner_3', bannerImgFile1)

        try {
            const response = await axios.post(
                `${imgUrl}/user/banner2-update`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )

            if (response.data.status) {
                toast.success('Banner updated successfully')
                fetchBannerList()
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Error updating banner')
        } finally {
            setloading(false)
        }
    }

    const handleImageChange = (file, setFile, setPreview) => {
        if (file) {
            setFile(file)
            setPreview(URL.createObjectURL(file))
        }
    }

    useEffect(() => {
        fetchBannerList()
    }, [])

    // Cleanup previews on unmount
    useEffect(() => {
        return () => {
            if (imgPreview) URL.revokeObjectURL(imgPreview)
            if (bannerPreview) URL.revokeObjectURL(bannerPreview)
            if (banner1Preview) URL.revokeObjectURL(banner1Preview)
        }
    }, [imgPreview, bannerPreview, banner1Preview])
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    Edit Bottom Banner Images
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Edit Bottom Banner Images
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="add-product__body px-sm-40 px-20">
                                    <form onSubmit={handleSubmit}>
                                        <div className='form-basic'>
                                            <div className='row'>
                                                <div className="col-lg-12 col-md-4 mb-3">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-500">banner image 1 (size in pixels (1920*520))</h6>
                                                        <label
                                                            htmlFor="upload"
                                                            className="file-upload__label"
                                                        >
                                                            <span className="upload-product-img px-10 d-block">
                                                                <span className="file-upload">
                                                                    <img
                                                                        className="svg"
                                                                        src={upload}
                                                                        alt=""
                                                                    />
                                                                    <input
                                                                        id="upload"
                                                                        className="file-upload__input"
                                                                        type="file"
                                                                        name="file-upload"
                                                                        // onChange={(e) => setImgFile(e.target.files[0])}
                                                                        onChange={(e) => handleImageChange(e.target.files[0], setImgFile, setImgPreview)}
                                                                    />
                                                                </span>
                                                            </span>
                                                        </label>
                                                        <div className="upload-product-media d-flex justify-content-between align-items-center mt-25">
                                                            <div className="upload-media-area">
                                                                <img
                                                                    src={`${imgUrl}/${imgPreview}` || media}
                                                                    alt="Banner 1"
                                                                />
                                                                {/* {
                                                                    imgFile ? (
                                                                        <img
                                                                            src={URL.createObjectURL(imgFile)}
                                                                            alt="img"
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={media}
                                                                            alt="img"
                                                                        />
                                                                    )
                                                                } */}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 mb-3">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-500">Banner image 2 (size in pixels (750*415))</h6>
                                                        <label
                                                            htmlFor="upload1"
                                                            className="file-upload__label"
                                                        >
                                                            <span className="upload-product-img px-10 d-block">
                                                                <span className="file-upload">
                                                                    <img
                                                                        className="svg"
                                                                        src={upload}
                                                                        alt=""
                                                                    />
                                                                    <input
                                                                        id="upload1"
                                                                        className="file-upload__input"
                                                                        type="file"
                                                                        name="file-upload"
                                                                        // onChange={(e) => setBannerImgFile(e.target.files[0])}
                                                                        onChange={(e) => handleImageChange(e.target.files[0], setBannerImgFile, setBannerPreview)}
                                                                    />
                                                                </span>
                                                            </span>
                                                        </label>
                                                        <div className="upload-product-media d-flex justify-content-between align-items-center mt-25">
                                                            <div className="upload-media-area">
                                                                <img
                                                                    src={`${imgUrl}/${bannerPreview}` || media}
                                                                    alt="Banner 2"
                                                                />
                                                                {/* {
                                                                    bannerImgFile ? (
                                                                        <img
                                                                            src={URL.createObjectURL(bannerImgFile)}
                                                                            alt="img"
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={media}
                                                                            alt="img"
                                                                        />
                                                                    )
                                                                } */}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 mb-3">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-500">Banner image 3 (size in pixels (750*415))</h6>
                                                        <label
                                                            htmlFor="upload11"
                                                            className="file-upload__label"
                                                        >
                                                            <span className="upload-product-img px-10 d-block">
                                                                <span className="file-upload">
                                                                    <img
                                                                        className="svg"
                                                                        src={upload}
                                                                        alt=""
                                                                    />
                                                                    <input
                                                                        id="upload11"
                                                                        className="file-upload__input"
                                                                        type="file"
                                                                        name="file-upload"
                                                                        // onChange={(e) => setBannerImgFile1(e.target.files[0])}
                                                                        onChange={(e) => handleImageChange(e.target.files[0], setBannerImgFile1, setBanner1Preview)}
                                                                    />
                                                                </span>
                                                            </span>
                                                        </label>
                                                        <div className="upload-product-media d-flex justify-content-between align-items-center mt-25">
                                                            <div className="upload-media-area">
                                                                <img
                                                                    src={`${imgUrl}/${banner1Preview}` || media}
                                                                    alt="Banner 3"
                                                                />
                                                                {/* {
                                                                    bannerImgFile1 ? (
                                                                        <img
                                                                            src={URL.createObjectURL(bannerImgFile1)}
                                                                            alt="img"
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={media}
                                                                            alt="img"
                                                                        />
                                                                    )
                                                                } */}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="button-group mt-1 add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                    <button type='submit' className="btn btn-primary btn-default btn-squared text-capitalize">
                                                        save Banner
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BottomBanner