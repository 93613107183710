import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import baseUrl from '../Helper/BaseUrl';
import { toast } from 'react-toastify';
const AddStaff = () => {
    const [roleList, setRoleList] = useState([])
    const [values, setValues] = useState({
        full_name: "",
        email: "",
        role: "",
    });
    const [loading, setLoading] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(preVal => ({
            ...preVal,
            [name]: value
        }))
    }
    const navigate = useNavigate()

    const fetchRoles = async () => {
        try {
            const res = await axios.get(`${baseUrl}/role-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("staff", res.data)
            setRoleList(res.data.data)
        } catch (error) {
            console.log("fetch staff error", error)
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const toastId = 'staff-Id'
        if (!values.full_name || !values.email || !values.role) {
            if (!toast.isActive(toastId)) {
                toast.error('All Field Required',{toastId:toastId})
            }
            return
        }
        setLoading(true)
        try {

            const res = await axios.post(`${baseUrl}/staff-store`, values, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            console.log(res)
            if (res.data.status) {
                toast.success(res.data.message);
                // setLoading(true)
                setLoading(false)
                navigate('/staff')

            } else {
                setLoading(false)
                toast.error(res.data.message)
            }
        } catch (error) {
            setLoading(false)
            console.log("error staff add", error)
        }
    }

    useEffect(() => {
        fetchRoles();
    }, [])
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>)}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    Add New Staff
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Add New Staff
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="add-product__body px-sm-40 px-20">
                                    <form onSubmit={handleSubmit}>
                                        <div className='form-basic'>
                                            <div className='row'>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">Staff Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='full_name'
                                                            value={values.full_name}
                                                            onChange={handleChange}
                                                            placeholder="staff  "
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name2">Staff Email</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name2"
                                                            name='email'
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            placeholder="staff@gmail.com"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <div className="countryOption">
                                                            <label htmlFor="countryOption">Staff Role</label>
                                                            <select
                                                                name='role'
                                                                value={values.role}
                                                                onChange={handleChange}
                                                                className="js-example-basic-single js-states form-control"
                                                                id="countryOption"
                                                            >
                                                                <option value="">Select role</option>
                                                                {
                                                                    roleList.map((elem) => {
                                                                        return (
                                                                            <option key={elem._id} value={elem._id}>{elem.role_name}</option>
                                                                        )
                                                                    })
                                                                }


                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="button-group add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                    <button type='submit' className="btn btn-primary btn-default btn-squared text-capitalize">
                                                        save Staff
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AddStaff