import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import ekologo from '../../assets/img/default-img.png'
const CountryManage = () => {
    const [catList, setCatList] = useState([]);
    const [loading, setloading] = useState(false);
    const [searchText, setSearchText] = useState('')
    useEffect(() => {
        fetchCountry();
    }, [searchText])
    
    // fetch country api
    const fetchCountry = async () => {
        // setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/country-list?name=${searchText}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("fetchCountry", res.data.data)
            setCatList(res.data.data)
            // setloading(false)
        } catch (error) {
            // setloading(false)
            console.log("fetch country error", error)
        }
    }

    const handleToggleStatus = async (catId, currentStatus) => {
        // console.log(currentStatus)
        try {
            setloading(true)
            const res = await axios.get(`${baseUrl}/country-status-change/${catId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
            // console.log(res);
            if (res.data.status) {
                toast.success(res.data.message)
                setloading(false)
                setCatList(prevCat =>
                    prevCat.map(cat =>
                        cat._id === catId ? { ...cat, is_active: !currentStatus } : cat
                    )
                )
            }
        } catch (error) {
            setloading(false)
            console.log("toggle country status error", error)
        }
    }
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main">
                            <h4 className="text-capitalize breadcrumb-title">Country list</h4>
                            <div className="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"><i className="uil uil-estate"></i>Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Country list</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header d-flex">
                                <form
                                    action=""
                                    className="user-member__form"
                                >

                                    <div className='with-icon'>
                                        <span className="uil uil-search"></span>
                                        <input
                                            className="form-control"
                                            type="search"
                                            placeholder="Search by Name"
                                            aria-label="Search"
                                            value={searchText}
                                            onChange={(e) => setSearchText(e.target.value)}
                                        />
                                    </div>

                                </form>
                            </div>
                            {
                                catList?.length > 0 ? (
                                    <div className="card-body">
                                        <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                                            <div className="table-responsive">
                                                <table className="table mb-0 table-borderless">
                                                    <thead>
                                                        <tr className="userDatatable-header">
                                                            <th><span className="userDatatable-title">S.No.</span></th>
                                                            {/* <th>
                                                        <span className="userDatatable-title">image</span>
                                                    </th> */}
                                                            <th>
                                                                <span className="checkbox-text userDatatable-title">
                                                                    Country Name
                                                                </span>
                                                            </th>
                                                            {/* <th>
                                                        <span className="userDatatable-title">Country Code</span>
                                                    </th> */}
                                                            <th>
                                                                <span className="userDatatable-title">status</span>
                                                            </th>
                                                            {/* <th>
                                                        <span className="userDatatable-title float-end">
                                                            action
                                                        </span>
                                                    </th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            catList.map((cat, index) => {
            
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <div className="userDatatable-content">{index + 1}</div>
                                                                        </td>
                                                                        {/* <td>
                                                                    <div className="userDatatable-content">
                                                                        <img src={cat.image.length > 0 && cat.image
                                                                            ? `${imgUrl}/${cat.image}`
                                                                            : ekologo
                                                                        }
                                                                            className="profile-image rounded-circle d-block m-0 wh-38" />
                                                                    </div>
                                                                </td> */}
                                                                        <td>
                                                                            <div className="userDatatable-inline-title">
                                                                                <Link to={`/userstatemanage/${cat.id}`} className="view">
                                                                                    <h6>{cat.name}</h6>
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                        {/* <td>
                                                                    <div className="userDatatable-content">
                                                                        {cat.code}
                                                                    </div>
                                                                </td> */}
                                                                        <td>
                                                                            <div className="userDatatable-content d-inline-block">
                                                                                <div className="form-check form-switch">
                                                                                    <input className="form-check-input" checked={cat.is_active}
                                                                                        onChange={() => handleToggleStatus(cat._id, cat.is_active)}
                                                                                        type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        {/* <td>
                                                                    <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                                        <li>
                                                                            <Link to={`/stateview/${cat._id}`} className="view">
                                                                                <i className="uil uil-eye" />
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to={`/editstate/${cat._id}`} className="edit">
                                                                                <i className="uil uil-edit" />
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </td> */}
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                ):(
                                    <h5 className='text-center m-3'>No Data Found</h5>
                                )
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CountryManage
