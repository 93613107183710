import React, { useEffect, useState } from 'react'
import searchImg from "../../assets/img/svg/search.svg"
import tm6 from "../../assets/img/tm6.png"
import { Link } from 'react-router-dom'
import axios from 'axios'
import baseUrl from '../Helper/BaseUrl'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
const Subscribers = () => {
    const [emailList, setEmailList] = useState([])
    const fetchSubscribers = async () => {
        try {
            const res = await axios.get(`${baseUrl}/news-letter-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            console.log(res)
            setEmailList(res.data.data)
        } catch (error) {
            console.log("error fetch emails", error)
        }
    }
    // delete api
    const handleEmailDelete = async (catId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    const res = await axios.delete(`${baseUrl}/news_letter-delete/${catId}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    })
                    if (res.data.status) {
                        toast.success(res.data.message)
                        setEmailList(prevCat => prevCat.filter(cat => cat._id !== catId));
                    } else {
                        toast.error(res.data.message)
                    }

                } catch (error) {
                    console.log('error delete order', error)
                }
            }
        })

    }
    useEffect(() => {
        fetchSubscribers();
    }, [])
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main">
                            <h4 className="text-capitalize breadcrumb-title">Subscribers</h4>
                            <div className="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"><i className="uil uil-estate"></i>Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Subscribers</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            {/* <div className="card-header">
                                <form
                                    action=""
                                    className="user-member__form"
                                >

                                    <div className='with-icon'>
                                        <span className="uil uil-search"></span>
                                        <input
                                            className="form-control"
                                            type="search"
                                            placeholder="Search by Name"
                                            aria-label="Search"
                                        // onChange={(e) => setSearchText(e.target.value)}
                                        />
                                    </div>
                                </form>
                            </div> */}
                            <div className="card-body">
                                <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                                    <div className="table-responsive">
                                        <table className="table mb-0 table-borderless">
                                            <thead>
                                                <tr className="userDatatable-header">
                                                    <th><span className="userDatatable-title">S.No.</span></th>
                                                    <th>
                                                        <span className="userDatatable-title">Email</span>
                                                    </th>
                                                    <th>
                                                        <span className="checkbox-text userDatatable-title">
                                                            Subscribed at
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="checkbox-text userDatatable-title float-end">
                                                           Action
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    emailList.map((emails, index) => {
                                                        return (
                                                            <tr key={emails._id}>
                                                                <td>
                                                                    <div className="userDatatable-content">{index + 1}</div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content">
                                                                        {emails.email}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-inline-title">

                                                                        <h6>{new Date(emails.createdAt).toLocaleString()}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                                        <li>
                                                                            <a type='button' onClick={() => handleEmailDelete(emails?._id)} className="remove">
                                                                                <i className="uil uil-trash-alt" />
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Subscribers