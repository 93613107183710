import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import ekologo from '../../assets/img/default-img.png'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import Pagination from '../Helper/Pagination';

const ProductList = () => {
    const [productList, setProductList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('')
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedTag, setSelectedTag] = useState('');
    const [imgFile, setImgFile] = useState(null);
    const [catList, setCatList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(1)
    const itemPerPage = 10;
    useEffect(() => {
        fetchCategory();
        fetchProduct();
    }, [searchText, selectedCategory, selectedTag, page])

    const fetchProduct = async () => {
        // setLoading(true)
        try {
            const res = await axios.get(`${baseUrl}/product-list?name=${searchText}&product_type=${selectedTag}&category=${selectedCategory}&page=${page}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("product", res.data)
            setProductList(res.data.data)
            setTotalItems(res.data.productcount)
            // setLoading(false)
        } catch (error) {
            // setLoading(false)
            console.log("fetch product error", error)
        }
    }
    // category api
    const fetchCategory = async () => {
        try {
            const res = await axios.get(`${baseUrl}/category-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("rescat", res.data.data)
            setCatList(res.data.data)
        } catch (error) {
            console.log("fetch cat error", error)
        }
    }
    // delete api
    const handleProductDelete = async (proId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await axios.delete(`${baseUrl}/product-delete/${proId}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    })
                    if (res.data.status) {
                        toast.success(res.data.message)
                        setProductList(prevPro => prevPro.filter(pro => pro._id !== proId));
                    } else {
                        toast.error(res.data.message)
                    }

                } catch (error) {
                    console.log('error delete subcategory', error)
                }
            }
        })

    }
    //  handle status change
    const handleAuthenticToggle = async (productId, currentStatus) => {
        try {
            setLoading(true)
            const newAuthentic = !currentStatus;
            const response = await axios.post(
                `${baseUrl}/product-authentic-change/${productId}`,
                { status: newAuthentic },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );
            if (response.data.status) {
                toast.success(response.data.message)
                setLoading(false)
                setProductList(prevState =>
                    prevState.map(prod =>
                        prod._id === productId ? { ...prod, authentic: newAuthentic } : prod
                    )
                );
            }
        } catch (error) {
            setLoading(false)
            console.error('Error changing product status:', error);
        }
    };
    //  handle status change
    const handleStatusToggle = async (productId, currentStatus) => {
        try {
            setLoading(true)
            const newStatus = !currentStatus;
            const response = await axios.post(
                `${baseUrl}/product-status-change/${productId}`,
                { status: newStatus },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );
            if (response.data.status) {
                toast.success(response.data.message)
                setLoading(false)
                setProductList(prevState =>
                    prevState.map(prod =>
                        prod._id === productId ? { ...prod, status: newStatus } : prod
                    )
                );
            }
        } catch (error) {
            setLoading(false)
            console.error('Error changing product status:', error);
        }
    };

    // Handle file upload to the server
    const handleUpload = async () => {
        try {
            const formData = new FormData();
            formData.append('file', imgFile);

            const response = await axios.post(`${baseUrl}/product-Upload`, formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    },
                });

            // console.log("response excel", response)
            if (response.data.status) {
                toast.success(response.data.message);
                fetchProduct();
                setImgFile(null)
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error(error.response.data.message)
        }
    };

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };
    //assign type and tag product api
    const handleProductTypeChange = async (productId, e) => {
        try {
            // const currentTypes = productTypes[productId] || '';
            const { value, checked } = e.target;
            // Get existing product types from the product in productList
            const product = productList.find(p => p._id === productId);
            // let currentTypes = product.product_type || '';
            console.log("product", product)
            // let typesArray = product.product_type ? product.product_type.trim().split(/\s+/) : [];
            // console.log("typesArray",typesArray)
            const validTypes = ['Popular Products', 'Bestsellers', 'New Arrivals'];
            let typesArray = product.product_type
                ? validTypes.filter(type => product.product_type.includes(type))
                : [];
            // Toggle the value
            if (checked && !typesArray.includes(value)) {
                typesArray.push(value);
            } else if (!checked) {
                typesArray = typesArray.filter(type => type !== value);
            }

            // Create clean comma-separated string
            const updatedTypes = typesArray.join(' ');

            // let currentTypes = productTypes[productId] || '';
            // let typesArray = currentTypes ? currentTypes.split(',') : [];

            // // Update types array based on checkbox
            // if (checked) {
            //     if (!typesArray.includes(value)) {
            //         typesArray.push(value);
            //     }
            // } else {
            //     typesArray = typesArray.filter(type => type !== value);
            // }

            // // Join types with comma and update state
            // const updatedTypes = typesArray.join(',');
            // setProductTypes(prev => ({
            //     ...prev,
            //     [productId]: updatedTypes
            // }));

            const response = await axios.post(
                `${baseUrl}/product-change-type`,
                {
                    product_type: updatedTypes,
                    product_id: productId
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );

            if (response.data.status) {
                toast.success(`Product type updated successfully`);
                setProductList(prevState =>
                    prevState.map(prod =>
                        prod._id === productId
                            ? { ...prod, product_type: updatedTypes }
                            : prod
                    )
                );
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error updating product type:', error);
            toast.error('Failed to update product type');
        }
    };
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main">
                            <h4 className="text-capitalize breadcrumb-title">Product</h4>
                            <div className="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"><i className="uil uil-estate"></i>Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Product list</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className='card mb-2'>
                            <div className='card-header'>
                                <div>
                                    <h6>Total Products ({totalItems})</h6>
                                </div>
                                <div>
                                    <Link to={`${baseUrl}/product-demo-file`} className="btn btn-primary" download style={{ cursor: 'pointer' }}><i class="fas fa-file-excel"></i>Downalod</Link>
                                </div>
                            </div>
                            <div className='card-body'>
                                <form action="" className="">
                                    <div className='row align-items-end'>
                                        <div className='col-lg-3'>
                                            <label >Search</label>
                                            <input className="form-control" type="search"
                                                placeholder="Search by Name" aria-label="Search"
                                                value={searchText}
                                                onChange={(e) => setSearchText(e.target.value)}
                                            />
                                        </div>
                                        <div className='col-lg-3'>
                                            <label>category</label>
                                            <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)} className="form-select" id="countryOption" >
                                                <option value="">Select Category</option>
                                                {
                                                    catList.map((cat, index) => {
                                                        return (
                                                            <option key={index} value={cat._id}>{cat.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className='col-lg-3'>
                                            <label>tag</label>
                                            <select className='form-select' value={selectedTag} onChange={(e) => setSelectedTag(e.target.value)}>
                                                <option value="">Select tag</option>
                                                <option value="New Arrivals">New Arrivals</option>
                                                <option value="Popular Products">Popular Products</option>
                                                <option value="Bestsellers">Bestsellers</option>
                                            </select>
                                        </div>
                                        <div className='col-lg-3 '>
                                            <label>File</label>
                                            <div className='d-flex'>
                                                <input type='file' className='form-control' onChange={(e) => setImgFile(e.target.files[0])} />
                                                <button type='button' className='btn btn-primary ms-1' onClick={handleUpload}>Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h5>Product list</h5>
                                <Link className="btn btn-primary" to="/addproduct"><span className="uil uil-plus"></span>Add Product</Link>
                            </div>
                            <div className="card-body">
                                {
                                    loading ? (
                                        <div id="overlayer">
                                            <div className="loader-overlay">
                                                <div className="dm-spin-dots spin-lg">
                                                    <span className="spin-dot badge-dot dot-primary"></span>
                                                    <span className="spin-dot badge-dot dot-primary"></span>
                                                    <span className="spin-dot badge-dot dot-primary"></span>
                                                    <span className="spin-dot badge-dot dot-primary"></span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : productList.length > 0 ? (
                                        <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                                            <div className="table-responsive">
                                                <table className="table mb-0 table-borderless table-striped">
                                                    <thead>
                                                        <tr className="userDatatable-header">
                                                            <th><span className="userDatatable-title">S.No.</span></th>
                                                            <th>
                                                                <span className="userDatatable-title">image</span>
                                                            </th>
                                                            <th>
                                                                <span className="checkbox-text userDatatable-title">
                                                                    Product title
                                                                </span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">add tag</span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">Price/Stock</span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">Authentic</span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">status</span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title float-end">
                                                                    action
                                                                </span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            productList.map((prod, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <div className="userDatatable-content"> {++index + 10 * (page - 1)}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content">
                                                                                <Link to={`/editproduct/${prod?._id}`} className="view">
                                                                                    <img src={prod?.product_images?.length > 0 && prod.product_images[0]?.image
                                                                                        ? `${imgUrl}/${prod.product_images[0].image}`
                                                                                        : ekologo
                                                                                    }
                                                                                        alt={prod?.product_name}
                                                                                        className="profile-image rounded-circle d-block m-0 wh-50" />
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-inline-title">
                                                                                <h6>{prod?.product_name}</h6>
                                                                                <div style={{ fontSize: "14px" }}>
                                                                                    ({prod?.category_id?.name})
                                                                                    ({prod?.location})
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" name="product_type" type="checkbox"
                                                                                    value="Popular Products"
                                                                                    checked={prod.product_type?.includes('Popular Products')}
                                                                                    onChange={(e) => handleProductTypeChange(prod._id, e)}
                                                                                    id={`popular-${prod._id}`} />
                                                                                <label className="form-check-label" htmlFor={`popular-${prod._id}`}>
                                                                                    Popular Products
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" name="product_type" type="checkbox"
                                                                                    checked={prod.product_type?.includes('New Arrivals')}
                                                                                    onChange={(e) => handleProductTypeChange(prod._id, e)}
                                                                                    value="New Arrivals" id={`new-${prod._id}`} />
                                                                                <label className="form-check-label" htmlFor={`new-${prod._id}`}>
                                                                                    New Arrivals
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" name="product_type" type="checkbox"
                                                                                    checked={prod.product_type?.includes('Bestsellers')}
                                                                                    onChange={(e) => handleProductTypeChange(prod._id, e)}
                                                                                    value="Bestsellers" id={`best-${prod._id}`} />
                                                                                <label className="form-check-label" htmlFor={`best-${prod._id}`}>
                                                                                    Bestsellers
                                                                                </label>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content">
                                                                                Price (150g): {prod?.weight_price?.[0]?.prices?.India} INR <br />
                                                                                Stock:{prod?.stock_qty}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content d-inline-block">
                                                                                <div className="form-check form-switch">
                                                                                    <input className="form-check-input" checked={prod?.authentic} onChange={() => handleAuthenticToggle(prod._id, prod.authentic)} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content d-inline-block">
                                                                                <div className="form-check form-switch">
                                                                                    <input className="form-check-input" checked={prod?.status} onChange={() => handleStatusToggle(prod._id, prod.status)} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                                                {/* <li>
                                                                                    <Link to={`/productview/${prod?._id}`} className="view">
                                                                                        <i className="uil uil-eye" />
                                                                                    </Link>
                                                                                </li> */}
                                                                                <li>
                                                                                    <Link to={`/editproduct/${prod?._id}`} className="edit">
                                                                                        <i className="uil uil-edit" />
                                                                                    </Link>
                                                                                </li>
                                                                                {/* <li>
                                                                                    <a type='button' onClick={() => handleProductDelete(prod?._id)} className="remove">
                                                                                        <i className="uil uil-trash-alt" />
                                                                                    </a>
                                                                                </li> */}
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <Pagination
                                                currentPage={page}
                                                onPageChange={handlePageChange}
                                                itemPerPage={itemPerPage}
                                                totalItems={totalItems}
                                            />
                                        </div>
                                    ) : (
                                        <div className="text-center p-4">
                                            <h4>No Products Found</h4>
                                            <p>Try adjusting your search criteria</p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductList