import React, { useEffect, useState } from 'react'
import doller from "../../assets/img/svg/dollar-sign.svg"
import percent from "../../assets/img/svg/percent.svg"
import media from "../../assets/img/food-restaurant-media.png"
import { Link, useNavigate, useParams } from 'react-router-dom'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import axios from 'axios'
const ProductView = () => {
    const { id } = useParams();
    const [values, setValues] = useState({
        category_id: "",
        brand_id: "",
        product_name: "",
        price: "",
        is_discount: false,
        discount_price: "",
        in_stock: false,
        stock_qty: "",
        authentic: "",
        is_variation_add: false,
        variation: "",
        description: "",
        sort_description: "",
        status: "",
        sequence: "",
        reviewRating: "",
        location: "",
        weight_price: [{ weight: "150g", prices: { India: "", Other: "", } }, { weight: "200g", prices: { India: "", Other: "", } }],
    })
    const [catList, setCatList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [variants, setVariants] = useState([]);
    const [loading, setloading] = useState(false)
    const [states, setStates] = useState([]);
    // product_images
    const [imgFile, setImgFiles] = useState([])
    const navigate = useNavigate();
    // category api
    const fetchCategory = async () => {
        try {
            const res = await axios.get(`${baseUrl}/category-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("rescat", res.data.data)
            setCatList(res.data.data)
        } catch (error) {
            console.log("fetch cat error", error)
        }
    }
    // // brands api
    // const fetchBrands = async () => {
    //   try {
    //     const res = await axios.get(`${baseUrl}/brand-list`, {
    //       headers: {
    //         Authorization: `Bearer ${token}`
    //       }
    //     })
    //     // console.log("brand", res.data.data)
    //     setBrandList(res.data.data)
    //   } catch (error) {
    //     console.log("fetch brand error", error)
    //   }
    // }
    // // vriants api
    // const fetchVariants = async () => {
    //   try {
    //     const res = await axios.get(`${baseUrl}/variation-list`, {
    //       headers: {
    //         Authorization: `Bearer ${token}`
    //       }
    //     })
    //     // console.log("var", res.data.data)
    //     setVariants(res.data.data)
    //   } catch (error) {
    //     console.log("fetch variants error", error)
    //   }
    // }

    // state list api

    const fetchStates = async () => {
        try {
            const res = await axios.get(`${imgUrl}user/state-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("state", res.data.data)
            setStates(res.data.data)
        } catch (error) {
            console.log("fetch states error", error)
        }
    }

    // product view api
    const fetchSingleProduct = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/product-view/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })

            // console.log(res)
            setValues(res.data.data)
            setImgFiles(res.data.data.product_images)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("single product error", error)
        }
    }

    useEffect(() => {
        fetchCategory();
        fetchStates();;
        fetchSingleProduct();
    }, [])
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    <li className="breadcrumb-item ">
                                        <Link to="/productlist">
                                            <i className="fa-solid fa-circle-arrow-left"></i>
                                            <span>Back</span>
                                        </Link>
                                    </li>
                                    / product Details
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                product Details
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className='card'>
                            <div className='card-header'></div>
                            <div className='card-body'>
                                <div className="add-product__body px-sm-40 px-20">
                                    <form >
                                        <div className='form-basic'>
                                            <div className='row'>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">Product Name</label>
                                                        <input type="text" className="form-control" id="name1" name='product_name' value={values.product_name} disabled placeholder="add product name" />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name2">Description</label>
                                                        <input type="text" className="form-control" name='description' value={values.description} disabled id="name2" placeholder="description" />
                                                    </div>
                                                </div>

                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name2">Sort Description</label>
                                                        <input type="text" className="form-control" name='sort_description' value={values.sort_description} disabled id="name2" placeholder="sort description"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <div className="countryOption">
                                                            <label htmlFor="countryOption">category</label>
                                                            <select name='category_id' value={values.category_id} disabled className="form-control" id="countryOption" >
                                                                <option value="">Select Category</option>
                                                                {
                                                                    catList.map((cat, index) => {
                                                                        return (
                                                                            <option key={index} value={cat._id}>{cat.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <div className="countryOption">
                            <label htmlFor="countryOption">brand</label>
                            <select name='brand_id' value={values.brand_id} disabled className="form-control" id="countryOption" >
                              <option value="">Select brand</option>
                              {
                                brandList.map((brand, index) => {
                                  return (
                                    <option key={index} value={brand._id}>{brand.brand_name}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </div> */}
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <div className="countryOption">
                                                            <label htmlFor="countryOption">Authentic</label>
                                                            <select name='authentic' value={values.authentic} disabled className="form-control" id="countryOption"
                                                            >
                                                                <option value="">Select Authentication</option>
                                                                <option value="true">Active</option>
                                                                <option value="false">Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <div className="countryOption">
                                                            <label htmlFor="countryOption">In Stock</label>
                                                            <select
                                                                name='in_stock'
                                                                value={values.in_stock}
                                                                disabled
                                                                className="form-control"
                                                                id="countryOption"
                                                            >
                                                                <option value="">Select Stock</option>
                                                                <option value={true}>In Stock</option>
                                                                <option value={false}>Out of Stock</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    values.in_stock === 'true' && (
                                                        <div className='col-lg-4 col-md-4 mb-3'>
                                                            <div className="form-group">
                                                                <div className="countryOption">
                                                                    <label htmlFor="countryOption">Stock Quantity</label>
                                                                    <input
                                                                        type="number"
                                                                        name='stock_qty'
                                                                        value={values.stock_qty}
                                                                        disabled
                                                                        className="form-control"
                                                                        id="name8"
                                                                        placeholder="enter stock quantity"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                {/* <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <div className="countryOption">
                            <label htmlFor="countryOption">Is Variation Add</label>
                            <select
                              name='is_variation_add'
                              value={values.is_variation_add}
                              disabled
                              className="form-control"
                              id="countryOption"
                            >
                              <option value="">Select Variation type</option>
                              <option value={true}>Active</option>
                              <option value={false}>InActive</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      {
                        values.is_variation_add === 'true' && (
                          <div className='col-lg-4 col-md-4 mb-3'>
                            <div className="form-group">
                              <div className="countryOption">
                                <label htmlFor="countryOption">variants</label>
                                <select name='variation' value={values.variation} disabled className="form-control" id="countryOption" >
                                  <option value="">Select variants</option>
                                  {
                                    variants.map((variant, index) => {
                                      return (
                                        <option key={index} value={variant._id}>{variant.variation_name}</option>
                                      )
                                    })
                                  }
                                </select>
                              </div>
                            </div>
                          </div>
                        )

                      } */}

                                                {/* <div className='col-lg-4 col-md-4 mb-3'>
                                                <div className="form-group quantity-appearance">
                                                    <label>price</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="basic-addon1" > <img className="svg" src={doller} alt="" />
                                                            </span>
                                                        </div>
                                                        <div className="pt_Quantity">
                                                            <input type="number" className="form-control" name='price' value={values.price} disabled // min={0} // max={100} // step={1} // defaultValue={0} // data-inc={1}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                                {values.weight_price.map((weightItem, index) => (
                                                    <div key={index} className="row">
                                                        <div className='col-lg-4 col-md-4 mb-3'>
                                                            <div className="form-group">
                                                                <label>{weightItem.weight}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={weightItem.weight}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-4 col-md-4 mb-3'>
                                                            <div className="form-group quantity-appearance">
                                                                <label>Indian price ({weightItem.weight})</label>
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                            <i className="fa-solid fa-indian-rupee-sign fs-3"></i>
                                                                        </span>
                                                                    </div>
                                                                    <div className="pt_Quantity">
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={weightItem.prices.India}
                                                                            disabled
                                                                        // onChange={(e) => handleWeightPriceChange(index, 'India', e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-4 col-md-4 mb-3'>
                                                            <div className="form-group quantity-appearance">
                                                                <label>International price ({weightItem.weight})</label>
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                            <i className="fa-solid fa-indian-rupee-sign fs-3"></i>
                                                                        </span>
                                                                    </div>
                                                                    <div className="pt_Quantity">
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={weightItem.prices.Other}
                                                                            disabled
                                                                        // onChange={(e) => handleWeightPriceChange(index, 'Other', e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <div className="countryOption">
                                                            <label htmlFor="countryOption">Is Discount</label>
                                                            <select name='is_discount' value={values.is_discount} disabled className="form-control" id="countryOption" >
                                                                <option value="">select Discount</option>
                                                                <option value={true}>Active</option>
                                                                <option value={false}>Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                {

                                                    values.is_discount === 'true' && (
                                                        <div className='col-lg-4 col-md-4 mb-3'>
                                                            <div className="form-group quantity-appearance">
                                                                <label>Discount Price</label>
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text" id="basic-addon2" > <img className="svg" src={percent} alt="" />
                                                                        </span>
                                                                    </div>
                                                                    <div className="pt_Quantity">
                                                                        <input type="number" className="form-control" name='discount_price' value={values.discount_price} disabled // min={0} // max={100} // step={1} // defaultValue={0} // data-inc={1}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )

                                                }

                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name8"> sequence </label>
                                                        <input type="number" name='sequence' value={values.sequence} disabled className="form-control" id="name8" placeholder="enter sequence" />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <div className="countryOption">
                                                            <label htmlFor="countryOption">Location</label>
                                                            <select
                                                                name='location'
                                                                value={values.location}
                                                                disabled
                                                                className="form-control"
                                                                id="countryOption"
                                                            >
                                                                <option value="">select state</option>\
                                                                {
                                                                    states.map((state, index) => {
                                                                        return (
                                                                            <option key={state._id} value={state.name}>{state.name}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <div className="countryOption">
                                                            <label htmlFor="countryOption">Status</label>
                                                            <select
                                                                name='status'
                                                                value={values.status}
                                                                disabled
                                                                className="form-control"
                                                                id="countryOption"
                                                            >
                                                                <option value="">select status</option>
                                                                <option value="true">Active</option>
                                                                <option value="false">Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-12 col-md-4 mb-3'>
                                                    <h6 className="fw-500">Product images</h6>
                                                    <div className='upload-part'>

                                                        {imgFile.length > 0 ? (
                                                            imgFile.map((file, index) => (
                                                                <div key={index} className="upload-part-innr">
                                                                    <img src={`${imgUrl}/${file.image}`} alt={`preview-${index}`} />
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <img src={media} alt="Default preview" />
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default ProductView


