import React, { useEffect, useState } from 'react'
import searchImg from "../../assets/img/svg/search.svg"
import axios from 'axios'
import baseUrl from '../Helper/BaseUrl'
import Pagination from '../Helper/Pagination'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

const UserList = () => {
    const [userList, setUserList] = useState([]);
    const [catList, setCatList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [emailText, setEmailText] = useState('');
    const [mobileText, setMobileText] = useState('');
    const [countryText, setCountryText] = useState('');
    const [loading, setloading] = useState(false);
    const itemPerPage = 10;

    useEffect(() => {
        fetchUserList();
        fetchCountry();
    }, [page, searchText, emailText, mobileText, countryText])

    // user list api
    const fetchUserList = async () => {
        try {
            const res = await axios.get(`${baseUrl}/user-list?page=${page}&name=${searchText}&mobile=${mobileText}&email=${emailText}&country=${countryText}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log(res.data)
            setUserList(res.data.data)
            setTotalItems(res.data.totalCount)
        } catch (error) {
            console.log("fetch user error", error)
        }
    }

    // fetch country api
    const fetchCountry = async () => {
        // setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/filter-country-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("fetchCountry", res.data.data)
            setCatList(res.data.data)
            // setloading(false)
        } catch (error) {
            // setloading(false)
            console.log("fetch country error", error)
        }
    }


    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    //  handle status change
    const handleStatusToggle = async (userId, currentStatus) => {
        try {
            setloading(true)
            const newStatus = !currentStatus;
            const response = await axios.get(
                `${baseUrl}/user-status-change/${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );
            // console.log(response)
            if (response.data.status) {
                toast.success(response.data.message)
                setloading(false)
                setUserList(prevState =>
                    prevState.map(list =>
                        list._id === userId ? { ...list, status: newStatus } : list
                    )
                );
            }
        } catch (error) {
            setloading(false)
            console.error('Error changing user status:', error);
        }
    };
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>)}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main">
                            <h4 className="text-capitalize breadcrumb-title">users list ({totalItems})</h4>
                            <div className="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"><i className="uil uil-estate"></i>Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">users list</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header d-flex">
                                <form
                                    action=""
                                    className="user-member__form"
                                >
                                    <div className='col-md-3'>
                                        <div className='with-icon m-2'>
                                            <span className="uil uil-search"></span>
                                            <input
                                                className="form-control"
                                                type="search"
                                                placeholder="Search by Name"
                                                aria-label="Search"
                                                value={searchText}
                                                onChange={(e) => setSearchText(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='with-icon m-2'>
                                            <span className="uil uil-search"></span>
                                            <input
                                                className="form-control"
                                                type="search"
                                                placeholder="Search by Email"
                                                aria-label="Search"
                                                value={emailText}
                                                onChange={(e) => setEmailText(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='with-icon m-2'>
                                            <span className="uil uil-search"></span>
                                            <input
                                                className="form-control"
                                                type="search"
                                                placeholder="Search by Mobile No."
                                                aria-label="Search"
                                                value={mobileText}
                                                onChange={(e) => setMobileText(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='with-icon'>
                                            <select value={countryText} onChange={(e) => setCountryText(e.target.value)} className="form-control" id="countryOption" >
                                                <option value="">Select Country</option>
                                                {
                                                    catList.map((cat, index) => {
                                                        return (
                                                            <option key={cat._id} value={cat.name}>{cat.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="card-body">
                                {
                                    userList?.length > 0 ? (
                                        <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                                            <div className="table-responsive">
                                                <table className="table mb-0 table-borderless">
                                                    <thead>
                                                        <tr className="userDatatable-header">
                                                            <th>
                                                                <span className="userDatatable-title">
                                                                    S. No.
                                                                </span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">
                                                                    user name
                                                                </span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">emaill/mobile</span>
                                                            </th>
                                                            {/* <th>
                                                                <span className="userDatatable-title">mobile</span>
                                                            </th> */}
                                                            <th>
                                                                <span className="userDatatable-title">gender</span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">country/state/city</span>
                                                            </th>
                                                            {/* <th>
                                                                <span className="userDatatable-title">state</span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">city</span>
                                                            </th> */}
                                                            <th>
                                                                <span className="userDatatable-title">createdAt</span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">status</span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title float-end">
                                                                    action
                                                                </span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            userList && userList.map((list, index) => {
                                                                return (
                                                                    <tr key={list._id}>
                                                                        <td>
                                                                            <div className="userDatatable-content">
                                                                                {++index + 10 * (page - 1)}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex">
                                                                                <div className="userDatatable-inline-title">
                                                                                    <h6>{list.full_name}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content">
                                                                                {list.email}
                                                                            </div>
                                                                            <div className="userDatatable-content">({list.mobile})</div>
                                                                        </td>
                                                                        {/* <td>
                                                                           
                                                                        </td> */}
                                                                        <td>
                                                                            <div className="userDatatable-content">{list.gender}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content">{list.country}</div>
                                                                            <div className="userDatatable-content">{list.state}</div>
                                                                            <div className="userDatatable-content">{list.city}</div>
                                                                        </td>
                                                                        {/* <td>
                                                                            <div className="userDatatable-content">{list.state}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content">{list.city}</div>
                                                                        </td> */}
                                                                        <td>
                                                                            <div className="userDatatable-content">{(list.createdAt).split("T")[0]}</div>
                                                                            <div className="userDatatable-content">{new Date(list.createdAt).toLocaleTimeString()}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content d-inline-block">
                                                                                <div className="form-check form-switch">
                                                                                    <input className="form-check-input" checked={list?.status} onChange={() => handleStatusToggle(list._id, list.status)} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                                                <li>
                                                                                    <Link to={`/userdetails/${list._id}`} className="view">
                                                                                        <i className="uil uil-eye" />
                                                                                    </Link>
                                                                                </li>
                                                                                <li>
                                                                                    <Link to={`/useredit/${list._id}`} className="edit">
                                                                                        <i className="uil uil-edit" />
                                                                                    </Link>
                                                                                </li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                            <Pagination
                                                currentPage={page}
                                                onPageChange={handlePageChange}
                                                itemPerPage={itemPerPage}
                                                totalItems={totalItems}
                                            />
                                        </div>
                                    ) : (
                                        <h4 className='text-center'>No Data Found</h4>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserList