import React, { useEffect, useState } from 'react'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'

const NotifyView = () => {
  const { id } = useParams();
  const [values, setValues] = useState({
    email: "",
    product_id: "",
    created_at: "",
  })
  // const [imgFile, setImgFile] = useState(null)
  const [loading, setloading] = useState(false)
  // category view api
  const fetchSingleCategory = async () => {
    setloading(true)
    try {
      const res = await axios.get(`${baseUrl}/notify-view/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      console.log(res)
      setValues(res.data.data)
      // setImgFile(res.data.data.category_image)
      setloading(false)
    } catch (error) {
      setloading(false)
      console.log("single category error", error)
    }
  }

  useEffect(() => {
    fetchSingleCategory();
  }, [])
  return (
    <>
      {
        loading && (
          <div id="overlayer">
            <div className="loader-overlay">
              <div className="dm-spin-dots spin-lg">
                <span className="spin-dot badge-dot dot-primary"></span>
                <span className="spin-dot badge-dot dot-primary"></span>
                <span className="spin-dot badge-dot dot-primary"></span>
                <span className="spin-dot badge-dot dot-primary"></span>
              </div>
            </div>
          </div>
        )}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="shop-breadcrumb">
              <div className="breadcrumb-main">
                <h4 className="text-capitalize breadcrumb-title">
                  <li className="breadcrumb-item ">
                    <Link to="/notifyemails">
                      <i className="fa-solid fa-circle-arrow-left"></i>
                      <span>Back</span>
                    </Link>
                  </li>
                  / Notify Emails
                </h4>
                <div className="breadcrumb-action justify-content-center flex-wrap">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <i className="uil uil-estate" />
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Notify Emails
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="add-product__body px-sm-40 px-20">
                  <form>
                    <div className='form-basic'>
                      <div className='row'>
                        <div className='col-lg-4 col-md-4 mb-3'>
                          <div className="form-group">
                            <label htmlFor="name1">email</label>
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name='email'
                              value={values?.email}
                              disabled
                            />
                          </div>
                        </div>
                        <div className='col-lg-4 col-md-4 mb-3'>
                          <div className="form-group">
                            <label htmlFor="name1">product</label>
                            <input
                              type="text"
                              className="form-control"
                              id="name1"
                              name='product_id'
                              value={values?.product_id}
                              disabled
                            />
                          </div>
                        </div>
                        <div className='col-lg-4 col-md-4 mb-3'>
                          <div className="form-group">
                            <label htmlFor="name1">created at</label>
                            <input
                              type="text"
                              className="form-control"
                              id="name1"
                              name='created_at'
                              value={new Date(values.created_at).toLocaleString()}
                              disabled
                              placeholder="description"
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-12 col-md-4 mb-3">
                          <div className="card-body p-0">
                            <h6 className="fw-500">Review image</h6>
                            <div className="upload-product-media d-flex justify-content-between align-items-center mt-25">
                              <div className="upload-media-area">
                                {
                                  imgFile && imgFile !== "" ? (
                                    <img
                                      src={`${imgUrl}/${imgFile}`}
                                      alt="img"
                                    />
                                  ) : (
                                    <img
                                      src={media}
                                      alt="media"
                                    />
                                  )
                                }
                              </div>
                            </div>

                          </div>
                        </div> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}

export default NotifyView