import React, { useEffect, useState } from 'react'
import searchImg from "../../assets/img/svg/search.svg"
import axios from 'axios';
import baseUrl from '../Helper/BaseUrl';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
const Order = () => {
    const [orderList, setOrderList] = useState([]);
    const [totalOrders, setTotalOrders] = useState("");
    const [loading, setloading] = useState(false)
    const [userText, setUserText] = useState("")
    const [statusText, setStatusText] = useState("")
    const [emailText, setEmailText] = useState("")
    const [orderId, setOrderId] = useState("")
    const [proText, setProText] = useState("")

    const fetchOrders = async () => {
        // setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/order-list?user_name=${userText}&status=${statusText}&email=${emailText}&order_id=${orderId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("orders", res.data.data)
            setTotalOrders(res.data.orderCount)
            setOrderList(res.data.data)
            // setloading(false)
        } catch (error) {
            // setloading(false)
            console.log("error fetch orders", error)
        }
    }
    // delete api
    const handleOrderDelete = async (catId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    const res = await axios.delete(`${baseUrl}/order-delete/${catId}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    })
                    if (res.data.status) {
                        toast.success(res.data.message)
                        setOrderList(prevCat => prevCat.filter(cat => cat._id !== catId));
                    } else {
                        toast.error(res.data.message)
                    }

                } catch (error) {
                    console.log('error delete order', error)
                }
            }
        })

    }

    const handleChangeOrderStatus = async (orderId, newStatus) => {
        try {
            const res = await axios.post(
                `${baseUrl}/order-change-status/${orderId}`,
                { status: newStatus },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            if (res.data.status) {
                toast.success("Order status updated successfully");
                setOrderList((prevList) =>
                    prevList.map((order) =>
                        order._id === orderId ? { ...order, status: newStatus } : order
                    )
                );
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            console.error("Error changing order status", error);
            toast.error("Failed to update order status");
        }
    };

    useEffect(() => {
        fetchOrders();
    }, [userText, statusText, emailText, orderId])
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main">
                            <h4 className="text-capitalize breadcrumb-title">Order list ({totalOrders})</h4>
                            <div className="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"><i className="uil uil-estate"></i>Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Order list</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header d-block py-3">
                                <form action="">
                                    <div className='row'>
                                        <div className='col-lg-3'>
                                            <label>Search</label>
                                            <input className="form-control" type="search"
                                                placeholder="Search by Name"
                                                value={userText}
                                                onChange={(e) => setUserText(e.target.value)}
                                                aria-label="Search" />
                                        </div>
                                        <div className='col-lg-3'>
                                            <label>Search By Email</label>
                                            <input className="form-control" type="search"
                                                placeholder="Search by Email"
                                                value={emailText}
                                                onChange={(e) => setEmailText(e.target.value)}
                                                aria-label="Search" />
                                        </div>
                                        <div className='col-lg-3'>
                                            <label>Search By Order Id</label>
                                            <input className="form-control" type="search"
                                                placeholder="Search by Order Id"
                                                value={orderId}
                                                onChange={(e) => setOrderId(e.target.value)}
                                                aria-label="Search" />
                                        </div>
                                        <div className='col-lg-3'>
                                            <label>Status</label>
                                            <select className='form-select' value={statusText} onChange={(e) => setStatusText(e.target.value)}>
                                                <option value="">Select Status</option>
                                                <option value="Ordered">Ordered</option>
                                                <option value="Packed">Packed</option>
                                                <option value="Dispatched">Dispatched</option>
                                                <option value="Shipped">Shipped</option>
                                                <option value="Intransit">Intransit</option>
                                                <option value="Delivered">Delivered</option>
                                                <option value="Cancelled - REFUND PENDING">Cancelled REFUND PENDING </option>
                                                <option value="Cancelled - REFUND PROCESSED">Cancelled REFUND PROCESSED</option>
                                                <option value="Cancelled - REFUND COMPLETE">Cancelled  REFUND COMPLETE</option>
                                            </select>
                                        </div>
                                        <div className='col-lg-3'>
                                            <label>Date</label>
                                            <input type='date' className='form-control' />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {
                                orderList?.length > 0 ? (
                                    <div className="card-body">
                                        <div className=" border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                                            <div className="table-responsive">
                                                <table className="table mb-0 table-striped">
                                                    <thead>
                                                        <tr className="userDatatable-header">
                                                            <th>
                                                                <span className="userDatatable-title">
                                                                    S.No.
                                                                </span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">
                                                                    order id
                                                                </span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">
                                                                    Customers
                                                                </span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">Status</span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">Amount</span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">
                                                                    Date
                                                                </span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">
                                                                    Change Order Status
                                                                </span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title float-end">
                                                                    Actions
                                                                </span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            orderList.map((list, index) => {
                                                                return (
                                                                    <tr key={list._id}>
                                                                        <td>
                                                                            <div className="userDatatable-content">
                                                                                {index + 1}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content">
                                                                                {list.order_id}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <Link to={`/userdetails/${list?.user_id?._id}`}>
                                                                                <div className="userDatatable-content">
                                                                                    {list?.user_id?.full_name}
                                                                                </div>
                                                                            </Link>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content">
                                                                                <span className={`order-bg-opacity-${list.status.toLowerCase().includes('cancelled') ? 'danger text-danger' : 'success text-success'} rounded-pill active p-1`}>
                                                                                    {list.status}
                                                                                </span>
                                                                                {/* <span className="order-bg-opacity-success  text-success rounded-pill active p-1">
                                                                                    {list.status}
                                                                                </span> */}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content">
                                                                                {(list.totalOrderAmountWithGSTAndCoupon)?.toFixed(2)}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content">
                                                                                {new Date(list.createdAt).toLocaleString()}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content">
                                                                                <select className='form-select' value={list.status}
                                                                                    onChange={(e) =>
                                                                                        handleChangeOrderStatus(
                                                                                            list._id,
                                                                                            e.target.value
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {/* <option value="">Select Status</option> */}
                                                                                    <option value="Ordered">Ordered</option>
                                                                                    <option value="Packed">Packed</option>
                                                                                    <option value="Dispatched">Dispatched</option>
                                                                                    <option value="Shipped">Shipped</option>
                                                                                    <option value="Intransit">Intransit</option>
                                                                                    <option value="Delivered">Delivered</option>
                                                                                    <option value="Cancelled REFUND PENDING">Cancelled REFUND PENDING </option>
                                                                                    <option value="Cancelled REFUND PROCESSED">Cancelled REFUND PROCESSED</option>
                                                                                    <option value="Cancelled REFUND COMPLETE">Cancelled  REFUND COMPLETE</option>
                                                                                </select>
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                                                <li>
                                                                                    <Link to={`/orderview/${list._id}`} className="view">
                                                                                        <i className="uil uil-eye" />
                                                                                    </Link>
                                                                                </li>
                                                                                {/* <li>
                                                                                    <a type='button' onClick={() => handleOrderDelete(list?._id)} className="remove">
                                                                                        <i className="uil uil-trash-alt" />
                                                                                    </a>
                                                                                </li> */}
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <h5 className='text-center p-3'>No Data Found</h5>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Order;