import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

const Siderbar = ({ isVisible, userPermissions }) => {
    // Separate state for each menu section
    const [openMenus, setOpenMenus] = useState({
        products: false,
        users: false,
        stock: false,
        reports: false,
        newsletter: false,
        refunds: false,
        deliverymens: false,
        rewards: false,
        hero: false,
        shipping: false,
        analytics: false,
        cms: false,
        gift: false,
        countr: false,
    });

    // Toggle specific menu section
    const toggleMenu = (menuName) => {
        setOpenMenus(prev => ({
            ...prev,
            [menuName]: !prev[menuName]
        }));
    };
    const hasPermission = (permission) => {
        return userPermissions.includes(permission);
    };
    const location = useLocation();

    return (
        <>
            <div className="sidebar-wrapper">
                <div className={`sidebar sidebar-collapse ${isVisible ? 'collapsed' : ''}`} id="sidebar">
                    <div className="sidebar__menu-group">
                        <ul className="sidebar_nav">
                            <li className='has-child open'>
                                <Link to="/" className="active text-decoration-none">
                                    <span className="nav-icon uil uil-create-dashboard" />
                                    <span className="menu-text">Dashboard</span>
                                </Link>
                            </li>
                            <li className={`has-child ${openMenus.users ? 'open' : ''}`}>
                                <a className="active text-decoration-none" onClick={() => toggleMenu('users')}>
                                    <span className="nav-icon uil uil-users-alt"></span>
                                    <span className="menu-text">Users</span>
                                    <span className="toggle-icon"></span>
                                </a>

                                <ul className={openMenus.users ? 'd-block' : 'd-none'}>
                                    {hasPermission("Manage staff") && (
                                        <li className={location.pathname === '/staff' ? 'active' : ''}>
                                            <Link to="/staff" className='text-decoration-none'>Team/Staff</Link>
                                        </li>)}
                                    {hasPermission("Manage users") && (
                                        <li className={location.pathname === '/userlist' ? 'active' : ''}>
                                            <Link to="/userlist" className='text-decoration-none'>Users</Link>
                                        </li>)}
                                </ul>
                            </li>
                            {/* Products Menu */}
                            <li className={`has-child ${openMenus.products ? 'open' : ''}`}>
                                <a className="active text-decoration-none" onClick={() => toggleMenu('products')}>
                                    <span className="nav-icon uil uil-bag" />
                                    <span className="menu-text">Products</span>
                                    <span className="toggle-icon"></span>
                                </a>
                                <ul className={openMenus.products ? 'd-block' : 'd-none'}>
                                    {hasPermission("Manage Products") && (
                                        <li className={location.pathname === '/productlist' ? 'active' : ''}>
                                            <Link to="/productlist" className='text-decoration-none'>Products List</Link>
                                        </li>)}
                                    {hasPermission("Manage Product Reviews") && (
                                        <li className={location.pathname === '/productreview' ? 'active' : ''}>
                                            <Link to="/productreview" className='text-decoration-none'>Products Reviews</Link>
                                        </li>)}
                                    {hasPermission("Manage Categories") && (
                                        <li className={location.pathname === '/categorylist' ? 'active' : ''}>
                                            <Link to="/categorylist" className='text-decoration-none'>Categories</Link>
                                        </li>)}
                                    {/* {hasPermission("Manage Sub Category") && (
                                        <li className={location.pathname === '/subcategorylist' ? 'active' : ''}>
                                            <Link to="/subcategorylist" className='text-decoration-none'>Sub Categories</Link>
                                        </li>)} */}
                                    {hasPermission("Manage Orders") && (
                                        <li className={location.pathname === '/order' ? 'active' : ''}>
                                            <Link to="/order" className='text-decoration-none'>Orders</Link>
                                        </li>)}
                                </ul>
                            </li>

                            <li className={`has-child ${openMenus.analytics ? 'open' : ''}`}>
                                <a className="active text-decoration-none" onClick={() => toggleMenu('analytics')}>
                                    <span className="nav-icon uil uil-analytics"></span>
                                    <span className="menu-text">notify Emails</span>
                                    <span className="toggle-icon"></span>
                                </a>
                                <ul className={openMenus.analytics ? 'd-block' : 'd-none'}>
                                    <li className={location.pathname === '/notifyemails' ? 'active' : ''}>
                                        <Link to="/notifyemails" className='text-decoration-none'>Notify Email List</Link>
                                    </li>
                                    {/* <li className={location.pathname === '/customersearch' ? 'active' : ''}>
                                        <Link to="/customersearch" className='text-decoration-none'>Customer Search</Link>
                                    </li> */}
                                </ul>
                            </li>

                            {hasPermission("Manage Roles") && (
                                <>
                                    <li className='has-child open'>
                                        <Link to="/rolelist" className="active text-decoration-none">
                                            <span className="nav-icon uil uil-question-circle" />
                                            <span className="menu-text">Roles</span>
                                        </Link>
                                    </li>
                                </>
                            )}
                            <li className='has-child open'>
                                <Link to="/couponlist" className="active text-decoration-none">
                                    <span className="nav-icon uil uil-gift" />
                                    <span className="menu-text">Coupons</span>
                                </Link>
                            </li>

                            {/* hero section Menu */}
                            <li className={`has-child ${openMenus.hero ? 'open' : ''}`}>
                                <a className="active text-decoration-none" onClick={() => toggleMenu('hero')}>
                                    <span className="nav-icon uil uil-home"></span>
                                    <span className="menu-text">Hero section</span>
                                    <span className="toggle-icon"></span>
                                </a>
                                <ul className={openMenus.hero ? 'd-block' : 'd-none'}>
                                    <li className={location.pathname === '/bannerslider' ? 'active' : ''}>
                                        <Link to="/bannerslider" className='text-decoration-none'>Banner Slider section</Link>
                                    </li>
                                    <li className={location.pathname === '/bottombanner' ? 'active' : ''}>
                                        <Link to="/bottombanner" className='text-decoration-none'>Bottom Banner section</Link>
                                    </li>
                                    <li className={location.pathname === '/faqlist' ? 'active' : ''}>
                                        <Link to="/faqlist" className='text-decoration-none'>FAQ</Link>
                                    </li>
                                    <li className={location.pathname === '/subscribers' ? 'active' : ''}>
                                        <Link to="/subscribers" className='text-decoration-none'>Subscribers</Link>
                                    </li>
                                    <li className={location.pathname === '/settings' ? 'active' : ''}>
                                        <Link to="/settings" className='text-decoration-none'>Settings</Link>
                                    </li>
                                    <li className={location.pathname === '/contacts' ? 'active' : ''}>
                                        <Link to="/contacts" className='text-decoration-none'>Help</Link>
                                    </li>
                                    {/* <li className={location.pathname === '/sale' ? 'active' : ''}>
                                        <Link to="/sale" className='text-decoration-none'>Seasonal Sale section</Link>
                                    </li>
                                    <li className={location.pathname === '/advertisement' ? 'active' : ''}>
                                        <Link to="/advertisement" className='text-decoration-none'>Advertisement Section</Link>
                                    </li> */}
                                </ul>
                            </li>
                            <li className={`has-child ${openMenus.cms ? 'open' : ''}`}>
                                <a className="active text-decoration-none" onClick={() => toggleMenu('cms')}>
                                    <span className="nav-icon uil uil-apps"></span>
                                    <span className="menu-text">cms section</span>
                                    <span className="toggle-icon"></span>
                                </a>
                                <ul className={openMenus.cms ? 'd-block' : 'd-none'}>
                                    <li className={location.pathname === '/cmslist' ? 'active' : ''}>
                                        <Link to="/cmslist" className='text-decoration-none'>CMS List</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={`has-child ${openMenus.shipping ? 'open' : ''}`}>
                                <a className="active text-decoration-none" onClick={() => toggleMenu('shipping')}>
                                    <span className="nav-icon uil uil-create-dashboard"></span>
                                    <span className="menu-text">Product State List</span>
                                    <span className="toggle-icon"></span>
                                </a>
                                <ul className={openMenus.shipping ? 'd-block' : 'd-none'}>
                                    <li className={location.pathname === '/statemanage' ? 'active' : ''}>
                                        <Link to="/statemanage" className='text-decoration-none'>Manage State</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={`has-child ${openMenus.countr ? 'open' : ''}`}>
                                <a className="active text-decoration-none" onClick={() => toggleMenu('countr')}>
                                    <span className="nav-icon uil uil-create-dashboard"></span>
                                    <span className="menu-text">user Country List</span>
                                    <span className="toggle-icon"></span>
                                </a>
                                <ul className={openMenus.countr ? 'd-block' : 'd-none'}>
                                    <li className={location.pathname === '/countrymanage' ? 'active' : ''}>
                                        <Link to="/countrymanage" className='text-decoration-none'>Manage user Country</Link>
                                    </li>
                                    <li className={location.pathname === '/userstatemanage/:id' ? 'active' : ''}>
                                        <Link to="/userstatemanage/101" className='text-decoration-none'>Manage user State</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={`has-child ${openMenus.gift ? 'open' : ''}`}>
                                <a className="active text-decoration-none" onClick={() => toggleMenu('gift')}>
                                    <span className="nav-icon uil uil-gift"></span>
                                    <span className="menu-text">Corporate Gift's</span>
                                    <span className="toggle-icon"></span>
                                </a>
                                <ul className={openMenus.gift ? 'd-block' : 'd-none'}>
                                    <li className={location.pathname === '/corporategiftlist' ? 'active' : ''}>
                                        <Link to="/corporategiftlist" className='text-decoration-none'>Corporate Gift's List</Link>
                                    </li>
                                    <li className={location.pathname === '/corporateimagelist' ? 'active' : ''}>
                                        <Link to="/corporateimagelist" className='text-decoration-none'>Corporate Gift's Images</Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Siderbar;
