import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import { toast } from 'react-toastify'
import ekologo from '../../assets/img/default-img.png'
const StateManage = () => {
    const [catList, setCatList] = useState([]);
    const [loading, setloading] = useState(false)
    useEffect(() => {
        fetchStates();
    }, [])

    // fetch category api
    const fetchStates = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/state-list-web`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("state", res.data)
            const stateData = res.data.data
            const sortedProducts = stateData.sort((a, b) => b.is_active - a.is_active);
            setCatList(sortedProducts)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("fetch state error", error)
        }
    }
    const handleToggleStatus = async (catId, currentStatus) => {
        console.log(currentStatus)
        try {
            setloading(true)
            const res = await axios.get(`${baseUrl}/web-state-status-change/${catId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
            // console.log(res);
            if (res.data.status) {
                toast.success(res.data.message)
                setloading(false)
                setCatList(prevCat =>
                    prevCat.map(cat =>
                        cat._id === catId ? { ...cat, is_active: !currentStatus } : cat
                    )
                )
            }
        } catch (error) {
            setloading(false)
            console.log("toggle category status error", error)
        }
    }
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main">
                            <h4 className="text-capitalize breadcrumb-title">State list</h4>
                            <div className="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"><i className="uil uil-estate"></i>Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">State list</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                                    <div className="table-responsive">
                                        <table className="table mb-0 table-borderless">
                                            <thead>
                                                <tr className="userDatatable-header">
                                                    <th><span className="userDatatable-title">S.No.</span></th>
                                                    <th>
                                                        <span className="userDatatable-title">image</span>
                                                    </th>
                                                    <th>
                                                        <span className="checkbox-text userDatatable-title">
                                                            state Name
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Sequence</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">status</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title float-end">
                                                            action
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    catList.map((cat, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <div className="userDatatable-content">{index + 1}</div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content">
                                                                        <img src={cat.image.length > 0 && cat.image
                                                                            ? `${imgUrl}/${cat.image}`
                                                                            : ekologo
                                                                        }
                                                                            className="profile-image rounded-circle d-block m-0 wh-38" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-inline-title">
                                                                        <h6>{cat.name}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content">
                                                                        {cat.sequence}
                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    <div className="userDatatable-content d-inline-block">
                                                                        <div className="form-check form-switch">
                                                                            <input className="form-check-input" checked={cat.is_active}
                                                                                onChange={() => handleToggleStatus(cat._id, cat.is_active)}
                                                                                type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                                        <li>
                                                                            <Link to={`/stateview/${cat._id}`} className="view">
                                                                                <i className="uil uil-eye" />
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to={`/editstate/${cat._id}`} className="edit">
                                                                                <i className="uil uil-edit" />
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StateManage
