import React, { useEffect, useState } from 'react'
import { imgUrl } from '../Helper/BaseUrl'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

const Settings = () => {
    const [values, setValues] = useState({
        address: "",
        contact: "",
        email: "",
        whatsapp_contact: "",
        social_links: "",
        social_links_1: "",
        social_links_2: "",
        social_links_3: "",
        social_links_4: "",
        social_links_5: "",
        currency_key: "",
        min_price: "",
        max_price: "",
        gst: 0,
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }
    const [loading, setloading] = useState(false)
    useEffect(() => {
        fetchSettingList();
    }, [])

    const fetchSettingList = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${imgUrl}user/web-setting-view`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("res", res)
            setValues(res.data.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("error fetch faq", error)
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(`${imgUrl}user/web-setting-update/676aaa9c94c69c73567e7162`, values, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("****",res);
            if (res.data.status) {
                toast.success(res.data.message)
            } else {
                toast.error(res.data.message)
            }
        } catch (error) {
            console.log("Edit Settings error", error)
        }
    }

    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    Edit Settings
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Edit Settings
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="add-product__body px-sm-40 px-20">
                                    <form onSubmit={handleSubmit}>
                                        <div className='form-basic'>
                                            <div className='row'>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">address</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='address'
                                                            value={values?.address}
                                                            onChange={handleChange}
                                                            placeholder="enter address"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">contact</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id="name1"
                                                            name='contact'
                                                            value={values?.contact}
                                                            onChange={handleChange}
                                                            placeholder="contact"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">email</label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            id="name1"
                                                            name='email'
                                                            value={values?.email}
                                                            onChange={handleChange}
                                                            placeholder="enter email"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">website link</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='social_links_1'
                                                            value={values?.social_links_1}
                                                            onChange={handleChange}
                                                            placeholder="enter website links"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">facebook</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='social_links_2'
                                                            value={values?.social_links_2}
                                                            onChange={handleChange}
                                                            placeholder="enter facebook links"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">twitter(x)</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='social_links_3'
                                                            value={values?.social_links_3}
                                                            onChange={handleChange}
                                                            placeholder="enter social links"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">instagram</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='social_links_4'
                                                            value={values?.social_links_4}
                                                            onChange={handleChange}
                                                            placeholder="enter instagram links"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">google</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='social_links_5'
                                                            value={values?.social_links_5}
                                                            onChange={handleChange}
                                                            placeholder="enter google links"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">Snapchat</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='social_links'
                                                            value={values?.social_links}
                                                            onChange={handleChange}
                                                            placeholder="enter snapchat links"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">whatsapp No.</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id="name1"
                                                            name='whatsapp_contact'
                                                            value={values?.whatsapp_contact}
                                                            onChange={handleChange}
                                                            placeholder="enter whatsapp No."
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">Currency Key</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='currency_key'
                                                            value={values?.currency_key}
                                                            onChange={handleChange}
                                                            placeholder="enter currency key"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">Max Price</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id="name1"
                                                            name='max_price'
                                                            value={values?.max_price}
                                                            onChange={handleChange}
                                                            placeholder="enter max price."
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">Min Price</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id="name1"
                                                            name='min_price'
                                                            value={values?.min_price}
                                                            onChange={handleChange}
                                                            placeholder="enter min price"
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">Gst</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id="name1"
                                                            name='gst'
                                                            value={values?.gst}
                                                            onChange={handleChange}
                                                            placeholder="enter gst in %"
                                                        />
                                                    </div>
                                                </div> */}

                                                <div className="button-group mt-1 add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                    <button type='submit' className="btn btn-primary btn-default btn-squared text-capitalize">
                                                        save Settings
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Settings


