import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import baseUrl from '../Helper/BaseUrl';
import { toast } from 'react-toastify';
import upload from "../../assets/img/svg/upload.svg"
import media from "../../assets/img/food-restaurant-media.png"
import trash from "../../assets/img/svg/trash-2.svg"
const StaffView = () => {
    const { id } = useParams();
    const [roleList, setRoleList] = useState([])
    const [values, setValues] = useState({
        full_name: "",
        email: "",
        role: "",
    });
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()

    const fetchRoles = async () => {
        try {
            const res = await axios.get(`${baseUrl}/role-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("staff", res.data)
            setRoleList(res.data.data)
        } catch (error) {
            console.log("fetch staff error", error)
        }
    }
    const fetchstaff = async () => {
        try {
            const res = await axios.get(`${baseUrl}/staff-view/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("staff", res.data)
            setValues(res.data.data)
        } catch (error) {
            console.log("fetch staff error", error)
        }
    }

    useEffect(() => {
        fetchstaff();
        fetchRoles();
    }, [])
    return (
        <>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    <li className="breadcrumb-item ">
                                        <Link to="/staff">
                                            <i className="fa-solid fa-circle-arrow-left"></i>
                                            <span>Back</span>
                                        </Link>
                                    </li>
                                    / Staff details
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Staff details
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card ">
                            <div className="card-body p-0">
                                <div className="add-product__body px-sm-40 px-20">
                                    <form >
                                        <div className='form-basic'>
                                            <div className='row'>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">Staff Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='full_name'
                                                            value={values.full_name}
                                                            disabled
                                                            placeholder="staff  "
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name2">Staff Email</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name2"
                                                            name='email'
                                                            value={values.email}
                                                            disabled
                                                            placeholder="staff@gmail.com"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <div className="countryOption">
                                                            <label htmlFor="countryOption">Staff Role</label>
                                                            <select
                                                                name='role'
                                                                value={values.role._id}
                                                                disabled
                                                                className="js-example-basic-single js-states form-control"
                                                                id="countryOption"
                                                            >
                                                                <option value="">Select role</option>
                                                                {
                                                                    roleList.map((elem) => {
                                                                        // console.log(elem)
                                                                        return (
                                                                            <option key={elem._id} value={elem._id}>{elem.role_name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StaffView